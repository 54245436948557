import React, { useEffect, useState } from "react"
import { useParams, useNavigate } from "react-router"

import axios from "../components/axios"
import MetaData, { metaDescription, metaKeywords, metaTitle } from "../components/MetaData"
import Breadcrumbs from "../components/navigation/Breadcrumbs"
import ExtraInfoBlock from "../components/ExtraInfoBlock"
import SearchProductWrapper from "../components/page-components/search/SearchProductWrapper"
import { defaultSearchFilter } from "../components/page-components/search/SearchFilterBlock"
import CategoryHeader from "../components/page-components/CategoryHeader"

/**
 * Subcat page
 */
const SubCategory = () => {
    const navigate = useNavigate()
    const { categoryRoute, subCategoryRoute } = useParams()

    const [searchFilter, setSearchFilter] = useState(null)
    const [pageInfo, setPageInfo] = useState()

    /**
     * Get the catogory data
     */
    const getCategoryDataAsync = async (abortController) => {
        try {
            const pageInfoRes = await axios.get(`/api/category/subcategory/pageinfo/${subCategoryRoute}`, { signal: abortController?.signal })
            if (pageInfoRes.status === 200) {
                setPageInfo(pageInfoRes.data)
                setSearchFilter({ ...defaultSearchFilter, categoryId: pageInfoRes.data?.categoryId, subCategoryUuid: pageInfoRes.data?.subCategoryUuid })
            } else {
                navigate("/")
            }
        } catch (e) {
            if (!abortController?.signal?.aborted) console.error(e)
        }
    }

    useEffect(() => {
        const abortController = new AbortController()
        getCategoryDataAsync(abortController)
        return () => abortController.abort()
    }, [subCategoryRoute])

    return (
        <>
            <MetaData description={metaDescription(pageInfo)} title={metaTitle(pageInfo)} keywords={metaKeywords(pageInfo)} />
            <div className='page-wrapper' id='s-page-top'>
                <>
                    <CategoryHeader
                        heading={pageInfo ? pageInfo.name : subCategoryRoute}
                        subheading={pageInfo && pageInfo.description}
                        backgroundImage={pageInfo && pageInfo.bannerUrl}
                    />

                    <div id='sc-page-top' className='content-section-wrapper'>
                        <ExtraInfoBlock text={pageInfo?.extraInfo} />

                        <div className='flex-row-center-vertical'>
                            <Breadcrumbs
                                type='SUBCAT'
                                category={{ category: pageInfo?.catName ?? categoryRoute, catRoute: pageInfo?.catRoute ?? categoryRoute }}
                                subCategory={{ category: pageInfo?.name ?? subCategoryRoute, subCatRoute: subCategoryRoute }}
                            />
                        </div>

                        <SearchProductWrapper baseSearchFilter={searchFilter} isParentLoading={searchFilter ? false : true} />
                    </div>
                </>
            </div>
        </>
    )
}

export default SubCategory
