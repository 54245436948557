import axios from "../components/axios"
import React, { createContext, useEffect, useState } from "react"

const SiteDataContext = createContext({})

/**
 * Site persistent lists
 */
export const SiteDataProvider = ({ children }) => {
    const [categories, setCategories] = useState(null)
    const [isLoadingSiteData, setIsLoadingSiteData] = useState(true)
    const [settings, setSettings] = useState(null)

    /**
     * Get the categories
     */
    const refreshSiteDataAsync = async (abortController) => {
        setIsLoadingSiteData(true)
        try {
            const [categoriesRes, settingsRes] = await Promise.all([
                axios.get("/api/category", { signal: abortController?.signal }),
                axios.get("/api/settings/public", { signal: abortController?.signal }),
            ])
            setCategories(categoriesRes.data)
            setSettings(settingsRes.data)
        } catch (e) {
            if (!abortController?.signal?.aborted) console.error(e)
        } finally {
            setIsLoadingSiteData(false)
        }
    }

    /**
     * Init page
     */
    useEffect(() => {
        const abortController = new AbortController()
        refreshSiteDataAsync(abortController)
        return () => abortController.abort()
    }, [])

    return (
        <SiteDataContext.Provider
            value={{
                isLoadingSiteData,
                categories,
                bannerMessage: settings?.bannerMessage,
                openingTimesWeekdays: settings?.openingTimesWeekdays ?? "00am - 00pm",
                openingTimesSunday: settings?.openingTimesSunday ?? "00am - 00pm",
                showInterestFreeCredit: settings?.showInterestFreeCredit ?? true,

                refreshSiteDataAsync,
            }}
        >
            {children}
        </SiteDataContext.Provider>
    )
}

export default SiteDataContext
