import React from "react"
import "./_styling/cookie-popup.css"
import CookieIcon from "@mui/icons-material/Cookie"
import Divider from "./Divider"

const CookiePopup = ({ accept, decline }) => {
    return (
        <div className='popup-card-wrapper'>
            <div className='popup-card'>
                <h1>
                    <CookieIcon /> Cookies
                </h1>
                <Divider padding={16} />
                <p>We use cookies on this website to improve your experience.</p>
                <p>Please choose your preferred option for cookies below.</p>
                <Divider padding={16} />
                <div className='flex-row'>
                    <button className='white-btn' onClick={accept}>
                        Accept Cookies
                    </button>
                    <button className='white-btn-text' onClick={decline}>
                        Decline Cookies
                    </button>
                </div>
            </div>
        </div>
    )
}

export default CookiePopup
