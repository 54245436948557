import React, { useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"

import "./_styling/brand.css"

import axios from "../components/axios"
import Divider from "../components/Divider"
import MetaData, { metaDescription, metaKeywords, metaTitle } from "../components/MetaData"
import SearchProductWrapper from "../components/page-components/search/SearchProductWrapper"
import { defaultSearchFilter } from "../components/page-components/search/SearchFilterBlock"
import SkeletonWrapper from "../components/mui/SkeletonWrapper"

/**
 * The brand page
 */
const Brand = () => {
    const navigate = useNavigate()
    const { brandUuid, categoryRoute, subCategoryRoute } = useParams()
    const [searchFilter, setSearchFilter] = useState(null)
    const [brand, setBrand] = useState(null)

    /**
     * Get the brand data
     */
    const getDataAsync = async (abortController) => {
        if (!brandUuid) return

        try {
            const [resBrand, resCategory] = await Promise.all([
                axios.get(`api/brand/${brandUuid}`, { signal: abortController?.signal }),
                axios.get(`api/category/id-from-route/${categoryRoute}/${subCategoryRoute ?? ""}`, { signal: abortController?.signal }),
            ])

            setBrand(resBrand.data)

            if (resBrand.status === 200) {
                let categoryId = null
                let subCategoryUuid = null
                if (resCategory.status === 200) {
                    categoryId = resCategory.data?.categoryId ?? categoryId
                    subCategoryUuid = resCategory.data?.subCategoryUuid ?? subCategoryUuid
                }
                setSearchFilter({ ...defaultSearchFilter, brandUuid: resBrand.data?.uuid, categoryId, subCategoryUuid })
            } else {
                navigate("/")
            }
        } catch (e) {
            if (!abortController?.signal?.aborted) console.error(e)
        }
    }

    /**
     * Page init
     */
    useEffect(() => {
        const abortController = new AbortController()
        getDataAsync(abortController)
        return () => abortController.abort()
    }, [brandUuid, categoryRoute, subCategoryRoute])

    return (
        <>
            <MetaData description={metaDescription(brand)} title={metaTitle(brand)} keywords={metaKeywords(brand)} />
            <div className='page-wrapper' id='s-page-top'>
                <div className='content-section-wrapper'>
                    <>
                        <section className='brand-header-wrapper'>
                            <div className='brand-header-logo'>
                                {brand ? (
                                    <img
                                        src={brand && brand.logoUrl}
                                        crossOrigin='anonymous'
                                        referrerPolicy='same-origin'
                                        alt={brand && brand.name}
                                        className='cover'
                                        fetchPriority='high'
                                    />
                                ) : (
                                    <SkeletonWrapper width='250px' height='100px' variant='rounded' />
                                )}
                            </div>
                            <div className='flex-column'>
                                {brand ? <h1>{brand?.name}</h1> : <SkeletonWrapper width='300px' height='50px' variant='text' />}
                                <p>{brand && brand.description}</p>
                            </div>
                        </section>
                        <Divider padding={16} />
                        <SearchProductWrapper baseSearchFilter={searchFilter} isParentLoading={searchFilter ? false : true} />
                    </>
                </div>
            </div>
        </>
    )
}

export default Brand
