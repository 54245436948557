import React from "react"
import { Link } from "react-router-dom"
import "../_styling/heading.css"

/**
 * Section headers
 */
const SectionHeading = ({ category, heading, subheading, linkTo = undefined }) => {
    return (
        <div className='section-heading-wrapper'>
            {category && <h2 className='section-heading-cat'>{category}</h2>}
            <hgroup>
                <h3>
                    {linkTo ? (
                        <Link to={linkTo} className='section-heading-header'>
                            {heading}
                        </Link>
                    ) : (
                        <span className='section-heading-header'>{heading}</span>
                    )}
                </h3>
                {subheading && <p>{subheading}</p>}
            </hgroup>
        </div>
    )
}

export default SectionHeading
