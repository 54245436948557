import React, { useState, useEffect } from "react"
import { useParams, useNavigate } from "react-router-dom"

import axios from "../components/axios"
import MetaData, { metaDescription, metaKeywords, metaTitle } from "../components/MetaData"
import Loading from "../components/Loading"
import SearchProductWrapper from "../components/page-components/search/SearchProductWrapper"
import { defaultSearchFilter } from "../components/page-components/search/SearchFilterBlock"
import CategoryHeader from "../components/page-components/CategoryHeader"

const Range = () => {
    const navigate = useNavigate()
    const { rangeRoute } = useParams()
    const [isLoadiing, setIsLoading] = useState(false)

    const [searchFilter, setSearchFilter] = useState(null)
    const [range, setRange] = useState(null)

    /**
     * Load the data
     */
    const getRangeInfoAsync = async (abortController) => {
        setIsLoading(true)
        try {
            const res = await axios.get(`/api/range/${rangeRoute}`, { signal: abortController?.signal })
            if (res.status === 200) {
                setRange(res.data)
                setSearchFilter({ ...defaultSearchFilter, rangeUuid: res.data?.uuid })
            } else {
                navigate("/")
            }
        } catch (e) {
            if (!abortController?.signal?.aborted) console.error(e)
        } finally {
            setIsLoading(false)
        }
    }

    /**
     * Init page
     */
    useEffect(() => {
        const abortController = new AbortController()
        getRangeInfoAsync(abortController)
        return () => abortController.abort()
    }, [rangeRoute])

    return (
        <>
            <MetaData description={metaDescription(range)} title={metaTitle(range)} keywords={metaKeywords(range)} />
            <div className='page-wrapper' id='s-page-top'>
                <CategoryHeader heading={range ? range.name : rangeRoute} subheading={range && range.description} backgroundImage={range && range.bannerUrl} />
                {range ? (
                    <div className='content-section-wrapper'>
                        <SearchProductWrapper baseSearchFilter={searchFilter} isParentLoading={searchFilter ? false : true} />
                    </div>
                ) : isLoadiing ? (
                    <Loading />
                ) : (
                    <div className='content-section-wrapper'>
                        <h2>Range not found</h2>
                    </div>
                )}
            </div>
        </>
    )
}

export default Range
