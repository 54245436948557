import React from "react"
import { useCollapse } from "react-collapsed"
import FilePresentRoundedIcon from "@mui/icons-material/FilePresentRounded"
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp"
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown"

const AdditionalInformation = ({ /*product, variantInfo,*/ files }) => {
    const { getCollapseProps, getToggleProps, isExpanded } = useCollapse({ defaultExpanded: true })

    if (!files || files.length === 0) return null

    return (
        <div className='collapsable-wrapper'>
            <button className='collapsable-btn' {...getToggleProps()}>
                <div className='flex-row-spacebetween full-width'>
                    <p>Additional Information</p>
                    {isExpanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                </div>
            </button>
            <section className='collapsable-info' {...getCollapseProps()}>
                <div className='flex-column p-flie-wrapper'>
                    {files.map((f) => {
                        return (
                            <a key={f.uuid} className='p-file flex-row' href={f.fileUrl} download={f.name} rel='noreferrer' target='_blank'>
                                <FilePresentRoundedIcon className='icon-faded' />
                                <p className='p-file-name'>{f.name}</p>
                            </a>
                        )
                    })}
                </div>
            </section>
        </div>
    )
}

export default AdditionalInformation
