import React from "react"

import "../_styling/about-us.css"

import Heading from "./Heading"
import { Link } from "react-router-dom"
import SectionHeading from "./SectionHeading"
import Divider from "../Divider"

/**
 * The about us snippet
 * @param {Object} props - The component props
 * @param {boolean} props.isSnippet - If the caller is a using this as a snippet or the full about us page
 */
function AboutUs({ isSnippet = true }) {
    return (
        <main id='about-us' className={isSnippet ? "snippet" : undefined}>
            <Heading heading={"About Us"} subheading={"Grimsby's Furniture Destination"} />
            <article>
                <section>
                    <p>
                        Ramsdens Home Interiors is one of the biggest furniture shops in Grimsby, Lincolnshire. If you need furniture then call or come visit us
                        in store today.
                    </p>
                </section>
                <section>
                    <SectionHeading heading='Create Your Dream Home' />
                    <p>
                        Unwind on plush sofas, discover the perfect dining set, or sink into a luxurious bed - all at Ramsdens Home Interiors, Grimsby&apos;s
                        one-stop shop for everything furniture! Located conveniently on Cleethorpe Road, we boast three floors brimming with living room
                        furniture, dining sets, bedroom furniture, carpets, laminates, rugs, curtains, blinds, wallpaper, and paint.
                    </p>
                </section>
                <section>
                    <SectionHeading heading='Shop With Ease' />
                    <p>
                        Enjoy the convenience of free customer parking while you browse our extensive selection. Our friendly staff is always happy to assist
                        you in finding the perfect furniture pieces for your home.
                    </p>
                </section>

                <Divider padding={16} percent={62} />

                <section>
                    <SectionHeading heading='Browse Our Extensive Selection' />
                    <ul>
                        <li>
                            <Link to='/store/sofas-chairs'>Sofas & Chairs</Link>: Find the perfect spot to relax with our vast selection of sofas, recliners,
                            armchairs, and more.
                        </li>
                        <li>
                            <Link to='/store/dining-living'>Dining Furniture</Link>: Discover the ideal table and chairs to elevate your next dinner party.{" "}
                        </li>
                        <li>
                            <Link to='/store/bedroom'>Bedrooms</Link>: Create your dream sanctuary with our beds, mattresses, and bedroom furniture collections.
                        </li>
                        <li>
                            <Link to='/store/nursery'>Nursery Furniture</Link>: Outfit your little one&apos;s space with our charming selection of cribs,
                            dressers, and changing tables.
                        </li>
                        <li>
                            <Link to='/store/curtains-blinds'>Curtains & Blinds</Link>: Dress your windows with ready made or made to mesure curtains and
                            blinds.
                        </li>
                        <li>
                            <Link to='/store/flooring'>Flooring</Link>: Spruce up your space with carpets, laminates, and stylish rugs.{" "}
                        </li>
                        <li>
                            <Link to='/store/decorating'>Décor</Link>: Find the finishing touches with our collection of wallpaper and paint.
                        </li>
                    </ul>
                </section>
            </article>
        </main>
    )
}

export default AboutUs
