import React from "react"
import MarkdownWrapper from "./MarkdownWrapper"
import Divider from "./Divider"

/**
 * Extra info block (shown on cat / sub-cat page)
 */
function ExtraInfoBlock({ text, className = "" }) {
    if (!text) return <></>
    if (typeof children === "string" && text?.trim() === "") return <></>

    return (
        <>
            <MarkdownWrapper className={`exta-info-wrapper ${className}`}>{text}</MarkdownWrapper>
            <Divider padding={16} percent={50} />
        </>
    )
}

export default ExtraInfoBlock
