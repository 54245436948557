import React from "react"
import { Link } from "react-router-dom"
import FacebookIcon from "@mui/icons-material/Facebook"
import InstagramIcon from "@mui/icons-material/Instagram"

import useSiteData from "../hooks/useSiteData"

import "./_styling/footer.css"
import IconButton from "./mui/IconButton"
import Loading from "./Loading"

/**
 * Site footer
 */
const Footer = () => {
    const { isLoadingSiteData, categories, openingTimesWeekdays, openingTimesSunday } = useSiteData()

    return (
        <div className='footer-wrapper'>
            <div className='footer-logo-wrapper full-width'>
                <div className='flex-center-item'>
                    <div className='f-logo-wrapper'>
                        <img
                            className='footer-image'
                            src='https://rsazure.blob.core.windows.net/rhi-images/ramsdens_logo_White.png'
                            alt='Ramdens Home Interior Logo'
                            loading='lazy'
                            crossOrigin='anonymous'
                            referrerPolicy='same-origin'
                        />
                    </div>
                </div>
                <div className='f-margin f-middle flex-row flex-wrap'>
                    <div className='flex-column flex-gap-none'>
                        <p className='f-title'>
                            <b>Location</b>
                        </p>
                        <address className='f-text-small h-adr'>
                            <p className='p-street-address'>Cleethorpe Road</p>
                            <p className='p-locality'>Grimsby</p>
                            <p className='p-postal-code'>DN31 3BP</p>
                        </address>
                    </div>
                    <div className='flex-column flex-gap-none'>
                        <p className='f-title'>
                            <b>Opening Times</b>
                        </p>
                        <div className='f-text-small'>
                            <p>
                                <b>Monday - Saturday</b>
                            </p>
                            <p>{openingTimesWeekdays}</p>
                            <p>
                                <b>Sunday</b>
                            </p>
                            <p>{openingTimesSunday}</p>
                        </div>
                    </div>
                    <div className='flex-column flex-gap-none'>
                        <p className='f-title'>
                            <b>Categories</b>
                        </p>
                        <div className='f-text-small '>
                            {isLoadingSiteData && <Loading color='white' />}
                            <ul className='f-list'>
                                {categories &&
                                    categories.map((c, i) => {
                                        return (
                                            <li key={i}>
                                                <Link to={`/store/${c.routeName}`}>{c.name}</Link>
                                            </li>
                                        )
                                    })}
                            </ul>
                        </div>
                    </div>
                </div>
                <div className='flex-center-item flex-wrap full-width'>
                    <a href={process.env.REACT_APP_FACEBOOK} target='_blank' rel='noreferrer' aria-label='Facebook'>
                        <IconButton hintText='Facebook'>
                            <FacebookIcon className='white-icon' sx={{ fontSize: "22px" }} alt='Facebook' />
                        </IconButton>
                    </a>

                    <a href={process.env.REACT_APP_INSTAGRAM} target='_blank' rel='noreferrer' aria-label='Instagram'>
                        <IconButton hintText='Instagram'>
                            <InstagramIcon className='white-icon' sx={{ fontSize: "22px" }} alt='Instagram' />
                        </IconButton>
                    </a>
                </div>
                <div className='flex-center-item flex-wrap full-width f-margin'>
                    <div className='f-text-small text-align-center'>
                        <ul className='f-inline-list'>
                            <li>
                                <Link to={"/terms"}>Terms &amp; Conditions</Link>
                            </li>
                            <li>
                                <Link to={"/disclaimer"}>Disclaimer</Link>
                            </li>
                            <li>
                                <Link to={"/privacy-policy"}>Privacy &amp; Cookie Policy</Link>
                            </li>
                            <li>
                                <Link to={"/vulnerable-customers-policy"}>Vulnerable Customers Policy</Link>
                            </li>
                            <li>
                                <Link to={"/customer-complaint"}>Customer Complaint</Link>
                            </li>
                            <li>
                                <Link to={"/interest-free-credit"}>Interest Free Credit</Link>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className='flex-center-item flex-wrap full-width'>
                    <p className='f-text-small text-align-center'>&copy;2023 Ramsdens Home Interiors</p>
                </div>
            </div>
        </div>
    )
}

export default Footer
