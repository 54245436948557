import React from "react"
import { useState } from "react"
import { useEffect } from "react"
import { Link } from "react-router-dom"

import "./_styling/homepage.css"

import axios from "../components/axios"
import LargeCarousel from "../components/carousel/LargeCarousel"
import MetaData, { metaDescription, metaKeywords, metaTitle } from "../components/MetaData"
import CategoryRow from "../components/page-components/CategoryRow"
import FindUs from "../components/page-components/FindUs"
import Heading from "../components/page-components/Heading"
import RowSection from "../components/page-components/RowSection"
import ProductRowSection from "../components/page-components/ProductRowSection"
import { motion } from "framer-motion"
import Divider from "../components/Divider"
import SkeletonWrapper from "../components/mui/SkeletonWrapper"
import AboutUs from "../components/page-components/AboutUs"

/**
 * The home page
 */
const Home = () => {
    const [isLoading, setIsLoading] = useState(true)
    const [hpRanges, setHpRanges] = useState(null)
    const [hpProducts, setHpProducts] = useState(null)

    /**
     * Get the pages main data
     */
    const getInitialData = async (abortController) => {
        setIsLoading(true)
        try {
            // Resolve the promises as they complete, as hp-sections is very slow
            await Promise.all([
                axios.get("/api/range/homepage", { signal: abortController?.signal }).then((res) => {
                    setHpRanges(res.data)
                }),

                axios.get("/api/search/hp-sections", { signal: abortController?.signal }).then((res) => {
                    setHpProducts(res.data)
                }),
            ])
        } catch (e) {
            if (!abortController?.signal?.aborted) console.error(e)
        } finally {
            setIsLoading(false)
        }
    }

    /**
     * Called when the page first loads
     */
    useEffect(() => {
        const abortController = new AbortController()
        getInitialData(abortController)

        // Clean up function
        return () => abortController.abort()
    }, [])

    return (
        <>
            <MetaData description={metaDescription(undefined, true)} title={metaTitle(undefined, true)} keywords={metaKeywords()} />
            <div className='page-wrapper'>
                <LargeCarousel />

                <div className='hp-range-wrapper'>
                    {isLoading ? (
                        <SkeletonWrapper
                            count={2}
                            width='100%'
                            height='100%'
                            variant='rounded'
                            callback={(skeleton) => (
                                <div className='hp-range'>
                                    <div className='hp-range-img-wrapper'>{skeleton}</div>
                                </div>
                            )}
                        />
                    ) : (
                        hpRanges?.map((r) => {
                            return (
                                <Link key={r.uuid} to={`/range/${r.routeName}`} className='hp-range'>
                                    <div className='hp-range-img-wrapper'>
                                        <motion.img
                                            whileHover={{
                                                scale: 1.05,
                                                transition: { duration: 0.2 },
                                            }}
                                            src={r.imageUrl}
                                            alt={r.name}
                                            crossOrigin='anonymous'
                                            referrerPolicy='same-origin'
                                        />
                                    </div>
                                    <motion.div
                                        initial={{ opacity: 0 }}
                                        animate={{ opacity: 1 }}
                                        transition={{ duration: 1 }}
                                        className='hp-range-name-wrapper'
                                    >
                                        <h1>{r.name}</h1>
                                        <p>{r.description}</p>
                                    </motion.div>
                                </Link>
                            )
                        })
                    )}
                </div>

                <Divider padding={16} percent={62} />
                <RowSection>
                    <Heading heading='Categories' subheading='Shop by category' />
                    <CategoryRow isLoading={isLoading} />
                </RowSection>
                <Divider padding={16} percent={62} />
                <div className='hp-product-rows-wrapper'>
                    <ProductRowSection
                        category='Special Offers'
                        heading='Available for a Limited Time Only'
                        subheading='Breathe new life into your home with stunning savings on sofas, beds, and more!'
                        linkTo='/special-offers'
                        products={hpProducts?.offers}
                        isLoading={isLoading}
                    />

                    <ProductRowSection
                        category='New In'
                        heading='Fresh Arrivals for Inspired Living Spaces'
                        subheading='Elevate your home with our newest arrivals, carefully curated to bring fresh inspiration and style to every corner.'
                        linkTo='/latest-products'
                        products={hpProducts?.newIn}
                        isLoading={isLoading}
                    />

                    <ProductRowSection
                        category='Sofas'
                        heading='Sofas that Define Comfort and Style'
                        subheading='Explore a collection of sofas that combine luxurious comfort with captivating style, elevating your living space.'
                        linkTo='/store/sofas-chairs/sofas'
                        products={hpProducts?.sofas}
                        isLoading={isLoading}
                    />

                    <ProductRowSection
                        category='Ready Made Curtains'
                        heading='Effortless Elegance for Your Windows'
                        subheading='Our ready-made curtains are crafted from high-quality fabrics, offering both visual appeal and a luxurious feel.'
                        linkTo='/store/bedroom/ready-made-curtains'
                        products={hpProducts?.curtains}
                        isLoading={isLoading}
                    />

                    <ProductRowSection
                        category='Divan Beds'
                        heading='Sleep in Blissful Comfort Every Night'
                        subheading='Experience the unparalleled comfort and support of our divan beds, designed to provide you with rejuvenating sleep.'
                        linkTo='/store/bedroom/divan-beds'
                        products={hpProducts?.beds}
                        isLoading={isLoading}
                    />

                    <AboutUs isSnippet={true} />

                    <Divider padding={16} percent={62} />

                    <RowSection>
                        <FindUs isSnippet={true} />
                    </RowSection>
                </div>
            </div>
        </>
    )
}

export default Home
