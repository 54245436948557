import React from "react"

import Divider from "../components/Divider"
import MetaData, { metaDescription, metaKeywords, metaTitle } from "../components/MetaData"
import FindUs from "../components/page-components/FindUs"

/**
 * The find us page
 */
function FindUsPage() {
    return (
        <div className='page-wrapper'>
            <div className='content-section-wrapper-sm'>
                <>
                    <MetaData
                        description={metaDescription("Find our location")}
                        title={metaTitle("Find us")}
                        keywords={metaKeywords({ tags: ["find us", "location", "map", "free parking"] })}
                    />
                    <main>
                        <h1>Find Us</h1>
                        <Divider padding={16} />
                        <FindUs isSnippet={false} />
                    </main>
                </>
            </div>
        </div>
    )
}

export default FindUsPage
