import React from "react"

const Unauthorised = () => {
    return (
        <div className='page-wrapper'>
            <div className='flex-column flex-center-item full-height'>
                <h3>Unauthorised</h3>
                <p>The requested page either does not exist, or you do not have access.</p>
            </div>
        </div>
    )
}

export default Unauthorised
