import React, { useEffect, useState } from "react"
import { Link, useLocation } from "react-router-dom"

import { getRouteName } from "../utility/RouteHelpers"

/**
 * Breadcrumbs
 */
const Breadcrumbs = ({ type, product, category, subCategory }) => {
    const [crumbs, setCrumbs] = useState(null)
    const loc = useLocation()

    useEffect(() => {
        let tempCrumbs
        const routes = loc.pathname.split("/")
        if (type === "PRODUCT") {
            tempCrumbs = [
                {
                    path: "/",
                    label: "Home",
                },
                {
                    path: `/store/${category.catRoute}`,
                    label: category.category,
                },
                {
                    path: `/store/${category.catRoute}/${subCategory.subCatRoute}`,
                    label: subCategory.category,
                },
                {
                    path: `/product/${product.uuid}/${getRouteName(product.name)}`,
                    label: product.name,
                },
            ]
        } else if (type === "SUBCAT") {
            tempCrumbs = [
                {
                    path: "/",
                    label: "Home",
                },
                {
                    path: `/store/${category.catRoute}`,
                    label: category.category,
                },
                {
                    path: `/store/${category.catRoute}/${subCategory.subCatRoute}`,
                    label: subCategory.category,
                },
            ]
        } else if (type === "CAT") {
            tempCrumbs = [
                {
                    path: "/",
                    label: "Home",
                },
                {
                    path: `/store/${category.catRoute}`,
                    label: category.category,
                },
            ]
        } else {
            tempCrumbs = routes.map((r) => {
                if (!r) return false
                const routeSplit = loc.pathname.split("/" + r + "/")
                let route = null
                if (routeSplit.length > 1) route = routeSplit[0] + "/" + r
                else route = routeSplit[0]
                return {
                    path: route,
                    label: r,
                }
            })
        }

        setCrumbs(tempCrumbs)
    }, [loc.pathname, type, category, subCategory, product])

    return (
        <div className='breadcrumb-wrapper'>
            <ul itemScope itemType='https://schema.org/BreadcrumbList'>
                {crumbs &&
                    crumbs.map((c, i) => {
                        return (
                            <li key={i} className='breadcrumb' itemProp='itemListElement' itemScope itemType='https://schema.org/ListItem'>
                                <Link to={c.path} className='breadcrumb-text' itemProp='item'>
                                    <span itemProp='name'>{c.label}</span>
                                </Link>
                                <meta itemProp='position' content={i + 1} />
                            </li>
                        )
                    })}
            </ul>
        </div>
    )
}

export default Breadcrumbs
