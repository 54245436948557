import React, { useEffect, useState } from "react"
import { useParams, useNavigate } from "react-router-dom"

import Divider from "../components/Divider"
import MetaData, { metaDescription, metaKeywords, metaTitle } from "../components/MetaData"
import axios from "../components/axios"
import Loading from "../components/Loading"
import MarkdownWrapper from "../components/MarkdownWrapper"
import { dateFormat } from "../components/utility/ConvertTime"

/**
 * Custom page
 * @param {Object} props - The page props
 * @param {string|undefined} props.fixedPageId - The fixed page guid
 */
const CustomPage = ({ fixedPageId = undefined }) => {
    const navigate = useNavigate()
    const { pageId } = useParams()
    const [pageData, setPageData] = useState(null)

    // Get the correct page id for this paghe
    const thisPageId = fixedPageId !== undefined ? fixedPageId : pageId

    /**
     * Load the data
     */
    const getDataAsync = async (abortController) => {
        if (!thisPageId) {
            navigate("/")
            return
        }

        try {
            const res = await axios.get(`/api/pages/${thisPageId}`, { signal: abortController?.signal })
            if (res.status === 200 && res.data?.visible) {
                setPageData(res.data)
            } else {
                navigate("/")
            }
        } catch (e) {
            if (!abortController?.signal?.aborted) console.error(e)
        }
    }

    /**
     * Init page data
     */
    useEffect(() => {
        const abortController1 = new AbortController()
        getDataAsync(abortController1)
        // Clean up function
        return () => {
            abortController1.abort()
        }
    }, [fixedPageId, pageId])

    return (
        <div className='page-wrapper'>
            <div className='content-section-wrapper-sm'>
                {pageData ? (
                    <>
                        <MetaData description={metaDescription(pageData)} title={metaTitle(pageData)} keywords={metaKeywords(pageData)} />
                        <article>
                            <header>
                                <h1>{pageData.title ?? "Untitled Page"}</h1>
                            </header>
                            <Divider padding={16} />
                            <MarkdownWrapper className='custom-page'>{pageData.body}</MarkdownWrapper>
                            <footer className='margin-top-8 text-align-right light-text text-small'>
                                Updated: <time dateTime={pageData.date}>{dateFormat(pageData.date)}</time>
                            </footer>
                        </article>
                    </>
                ) : (
                    <Loading />
                )}
            </div>
        </div>
    )
}

export default CustomPage
