import React, { Fragment } from "react"
import { Helmet } from "react-helmet-async"
import { defaultKeywords } from "./constants"

/**
 * Creates a single meta tag
 * @param {string|undefined} name the name to use use or undefined
 * @param {string|undefined} property the property to use use or undefined
 * @param {string|undefined} content the content to use, to empty no tag will be rendered
 * @param {bool} noIndex
 * @returns
 */
function metaTag(name, property, content, noIndex = false) {
    if (noIndex || !content) return null
    if (name) return { name, content }
    if (property) return { property, content }
    return null
}

function linkTag(rel, href) {
    if (rel) return { rel, href }
    return null
}

/**
 * Gets a single script tag
 */
function scriptTag(script) {
    if (typeof script === "string") return { src: script, async: true, type: "text/javascript" }

    return null
}

/**
 * If the passed item is an object or not
 */
function isObject(item) {
    return typeof item === "object" && item !== null
}

/**
 * Gets the meta data description
 */
export function metaDescription(item, isFrontPage = false) {
    let description = process.env.REACT_APP_META_DESCRIPTION_FRONT_PAGE

    if (!isFrontPage) {
        let items = []
        if (isObject(item)) {
            const name = item?.name ?? item?.title
            const pageTitle = item?.pageTitle
            const subTitle = item?.subTitle
            const catName = item?.catName ?? item?.category
            const subCatName = item?.subCategory ?? item?.category
            items.push(name)
            items.push(pageTitle)
            items.push(subTitle)
            items.push(catName)
            items.push(subCatName)
        }
        items.push(process.env.REACT_APP_META_DESCRIPTION)
        description = items.filter(Boolean).join(" | ")
    }

    return description
}

/**
 * Gets the meta data title
 */
export function metaTitle(item, isFrontPage = false) {
    let title = process.env.REACT_APP_META_TITLE_FRONT_PAGE

    if (!isFrontPage) {
        let items = []
        if (isObject(item)) {
            const name = item?.name ?? item?.title
            const subTitle = item?.subTitle
            const catName = item?.catName ?? item?.category
            const subCatName = item?.subCategory ?? item?.category
            const pageTitle = item?.pageTitle

            if (name) items.push(name)
            if (pageTitle) items.push(pageTitle)
            if (subTitle) items.push(subTitle)
            if (items.length < 2 && subCatName) items.push(subCatName)
            if (items.length < 2 && catName) items.push(catName)
        }
        items.push(process.env.REACT_APP_META_TITLE)
        title = items.filter(Boolean).join(" | ")
    }
    return title
}

/**
 * Gets the meta data keywords
 */
export function metaKeywords(item) {
    let keywords = []
    if (isObject(item)) {
        const name = item?.name ?? item?.title
        const catName = item?.catName ?? item?.category
        const subCatName = item?.subCategory ?? item?.category
        const tags = Array.isArray(item?.tags) ? item?.tags : []
        keywords = tags
        keywords.push(name?.toLowerCase())
        keywords.push(catName?.toLowerCase())
        keywords.push(subCatName?.toLowerCase())
    }

    return [...keywords, ...defaultKeywords].filter(Boolean)
}

/**
 * Used to update the sites meta data in the head section
 */
function MetaData({
    title = undefined,
    description = undefined,
    noIndex = false,
    keywords = [],
    pageType = undefined,
    pageImage = undefined,
    canonicalUrl = undefined,
    siteName = undefined,
    scripts = [],
}) {
    //facebook tags
    //@see https://developers.facebook.com/docs/sharing/webmasters/
    const url = canonicalUrl ? canonicalUrl : window.location.href
    const image = pageImage ? pageImage : `${process.env.REACT_APP_SITE_URI}logo512.png`
    const pType = pageType ? pageType : "website"

    const robots = noIndex ? "noindex, noimageindex, nofollow, noarchive, nocache, nosnippet, noodp, noydir" : "index, follow"

    // NOTE: the below will be converted into <meta/> tags
    // maybe better we should use useMemo here?
    // .filter((n) => n)  at the end if to remove nulls
    const metaTags = [
        // Basic meta tags
        metaTag("description", undefined, description, noIndex),
        metaTag("keywords", undefined, keywords?.join(", ")),
        metaTag("robots", undefined, robots),
        // Facebook open graph
        metaTag(undefined, "og:url", url, noIndex),
        metaTag(undefined, "og:type", pType, noIndex),
        metaTag(undefined, "og:title", title, noIndex),
        metaTag(undefined, "og:description", description, noIndex),
        metaTag(undefined, "og:image", image, noIndex),
        metaTag(undefined, "og:site_name", siteName ?? process.env.REACT_APP_META_SITENAME, noIndex),
        // Twitter meta tags
        // domain is example.com (without https:// or path)
        metaTag(undefined, "twitter:domain", process.env.REACT_APP_SITE_DOMAIN, noIndex),
        metaTag("twitter:card", undefined, "summary", noIndex),
        metaTag(undefined, "twitter:url", url, noIndex),
        metaTag("twitter:title", undefined, title, noIndex),
        metaTag("twitter:description", undefined, description, noIndex),
        metaTag("twitter:image", undefined, image, noIndex),
    ].filter((n) => n)

    // NOTE: the below will be converted into <link/> tags
    const linkTags = [linkTag("canonical", url)].filter((n) => n)

    const scriptTags = Array.isArray(scripts) ? scripts?.map((s) => scriptTag(s)) : undefined

    // test this at - https://www.opengraph.xyz/
    return <Helmet meta={metaTags} link={linkTags} title={title ? title : undefined} script={scriptTags} />
}

export function AdminMetaData({ title = "" }) {
    return (
        <>
            <MetaData title={`${title} | Admin`} noIndex={true} />
            <Helmet title={title ? `${title} | Admin` : undefined}>
                <meta name='robots' content='noindex, noimageindex, nofollow, noarchive, nocache, nosnippet, noodp, noydir' />
            </Helmet>
        </>
    )
}

export default MetaData
