import React from "react"
import { Link } from "react-router-dom"
import EmailIcon from "@mui/icons-material/Email"
import PhoneIcon from "@mui/icons-material/Phone"
import PlaceIcon from "@mui/icons-material/Place"
import FacebookIcon from "@mui/icons-material/Facebook"
import InstagramIcon from "@mui/icons-material/Instagram"
import LocalParkingIcon from "@mui/icons-material/LocalParking"

import "../_styling/find-us.css"

import Heading from "./Heading"
import useSiteData from "../../hooks/useSiteData"

/**
 * The find us block
 * @param {Object} props - The component props
 * @param {boolean} props.isSnippet - If the caller is a using this as a snippet or the full find us page
 */
const FindUs = ({ isSnippet = true }) => {
    const { openingTimesWeekdays, openingTimesSunday } = useSiteData()

    return (
        <div id='find-us' itemScope itemType='https://schema.org/Store'>
            <meta itemProp='name' content={process.env.REACT_APP_META_SITENAME} />
            <meta itemProp='description' content={process.env.REACT_APP_META_STORE_DESCRIPTION} />
            <meta itemProp='url' content={process.env.REACT_APP_SITE_URI} />
            <meta itemProp='priceRange' content='£15-£5000' />
            <meta itemProp='currenciesAccepted' content='GBP' />
            <meta itemProp='paymentAccepted' content='Cash, Credit Card, Interest Free Credid' />
            <meta itemProp='areaServed' content='Grimsby, Cleethorpes, Lincoln, Lincolnshire, Hull, Scunthorpe, Louth' />
            <meta itemProp='logo' content='https://rsazure.blob.core.windows.net/rhi-images/logo.png' />
            <meta itemProp='image' content='https://rsazure.blob.core.windows.net/rhi-images/logo.png' />

            <Heading
                heading={isSnippet ? "How To Find Us" : "Visit us at our Grimsby store"}
                subheading={isSnippet ? undefined : "With convenient free parking for customers available at the rear of the store on Thorold Street."}
            />
            <div className={`${isSnippet ? "find-us-wrapper-column" : "find-us-wrapper-row"} full-width`}>
                <div className='fu-left flex-row flex-wrap flex-spacebetween'>
                    <div className='find-us-item flex-column flex-gap-2'>
                        <div className='flex-row-center-vertical red-subtitle'>
                            <PlaceIcon />
                            <h2>Address</h2>
                        </div>

                        <address className='find-us-content fu-address h-adr' itemProp='address' itemScope itemType='https://schema.org/PostalAddress'>
                            <h1 className='p-name' itemProp='name'>
                                {process.env.REACT_APP_META_SITENAME}
                            </h1>
                            <div>
                                <p className='p-street-address' itemProp='streetAddress'>
                                    361 Cleethorpe Road
                                </p>
                                <p className='p-locality' itemProp='addressLocality'>
                                    Grimsby
                                </p>
                                <p className='p-region' itemProp='addressRegion'>
                                    North East Lincolnshire
                                </p>
                                <p className='p-postal-code' itemProp='postalCode'>
                                    DN31 3BP
                                </p>
                            </div>
                        </address>
                    </div>

                    {isSnippet ? null : (
                        <div className='find-us-item flex-column flex-gap-2'>
                            <div className='flex-row-center-vertical red-subtitle'>
                                <LocalParkingIcon />
                                <h2>Free Parking</h2>
                            </div>
                            <address className='find-us-content'>
                                <h1 className='p-name' itemProp='name'>
                                    <span className='hidden'>{process.env.REACT_APP_META_SITENAME}</span> Customer Car Park
                                </h1>
                                <div>
                                    <p className='p-street-address' itemProp='streetAddress'>
                                        Thorold Street
                                    </p>
                                    <p className='p-locality' itemProp='addressLocality'>
                                        Grimsby
                                    </p>
                                    <p className='p-region' itemProp='addressRegion'>
                                        North East Lincolnshire
                                    </p>
                                </div>
                            </address>
                            <div className='find-us-content'>
                                <h2>what3words</h2>
                                <a href='https://w3w.co/maybe.foster.movie' target='_blank' rel='noreferrer' aria-label='What 3 words'>
                                    <span className='red-text'>&#47;&#47;&#47;</span>maybe.foster.movie
                                </a>
                            </div>
                        </div>
                    )}

                    <div className='find-us-item flex-column flex-gap-none'>
                        <div className='flex-row-center-vertical red-subtitle'>
                            <h2>Opening Times</h2>
                            <meta itemProp='openingHours' content={`Mo,Tu,We,Th,Fr,Sa ${openingTimesWeekdays}`} />
                            <meta itemProp='openingHours' content={`Su ${openingTimesSunday}`} />
                        </div>
                        <div className='find-us-content'>
                            <div className='flex-column flex-gap-none'>
                                <p className='fu-days'>Monday - Saturday</p>
                                <p className='fu-times'>{openingTimesWeekdays}</p>
                            </div>
                            <div className='flex-column flex-gap-none'>
                                <p className='fu-days'>Sunday</p>
                                <p className='fu-times'>{openingTimesSunday}</p>
                            </div>
                        </div>
                    </div>

                    <div className='find-us-item fu-contact flex-column flex-gap-none'>
                        <div className='flex-row-center-vertical red-subtitle'>
                            <h2>Contact Us</h2>
                        </div>
                        <div className='find-us-content'>
                            <Link to={`tel:${process.env.REACT_APP_PHONE_LINK}`} className='flex-row-center-vertical fu-call'>
                                <PhoneIcon className='icon' sx={{ fontSize: "18px" }} />
                                <p className='fu-contact-text visible-sm'>Call Us</p>
                                <p className='fu-contact-text fu-call hidden-sm' itemProp='telephone' content={`${process.env.REACT_APP_PHONE_LINK}`}>
                                    {process.env.REACT_APP_PHONE}
                                </p>
                            </Link>
                            <Link to={`/contact`} className='flex-row-center-vertical'>
                                <EmailIcon className='icon' sx={{ fontSize: "18px" }} />
                                <p className='fu-contact-text'>Email Us</p>
                            </Link>
                            <a
                                href={process.env.REACT_APP_FACEBOOK}
                                aria-label='Facebook'
                                className='flex-row-center-vertical'
                                target='_blank'
                                rel='noreferrer'
                            >
                                <FacebookIcon className='icon' sx={{ fontSize: "18px" }} alt='Facebook' />
                                <p className='fu-contact-text'>Facebook</p>
                            </a>
                            <a
                                href={process.env.REACT_APP_INSTAGRAM}
                                aria-label='Instagram'
                                className='flex-row-center-vertical'
                                target='_blank'
                                rel='noreferrer'
                            >
                                <InstagramIcon className='icon' sx={{ fontSize: "18px" }} alt='Instagram' />
                                <p className='fu-contact-text'>Instagram</p>
                            </a>
                        </div>
                    </div>
                </div>
                <div className='find-us-map'>
                    <iframe
                        title='RHILocation'
                        src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2369.138420041029!2d-0.06308068399145118!3d53.57314616539953!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x48789cd0f3bdbe73%3A0x12bd7fa10717c23b!2sRamsdens%20Home%20Interiors!5e0!3m2!1sen!2suk!4v1680516923758!5m2!1sen!2suk'
                        width='100%'
                        height='100%'
                        loading='lazy'
                        allowFullScreen
                        referrerPolicy='no-referrer-when-downgrade'
                    ></iframe>
                </div>
            </div>
        </div>
    )
}

export default FindUs
