import React from "react"
import { Drawer } from "@mui/material"
import { styled } from "@mui/material/styles"
import FilterListIcon from "@mui/icons-material/FilterList"

const drawerStyle = {
    width: "300px",
    flexShrink: 0,
    "& .MuiDrawer-paper": {
        width: "300px",
    },
}

const FilterDrawerHeader = styled("div")(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-start",
}))

/**
 * Filters pill
 */
const FilterModule = ({ isOpen, toggleIsOpen, children }) => {
    return (
        <>
            <div className='filter-module-wrapper'>
                <div className='filter-button flex-row-center-vertical' onClick={toggleIsOpen}>
                    <FilterListIcon style={{ color: "#fff", fontSize: "20px" }} />
                    <p>Filters</p>
                </div>
            </div>

            <Drawer sx={drawerStyle} anchor='right' open={isOpen} variant='temporary' onClose={toggleIsOpen}>
                <FilterDrawerHeader className='divider-line-bottom-black'>
                    <h2 style={{ paddingInline: "8px" }}>Filters</h2>
                </FilterDrawerHeader>
                {children}
            </Drawer>
        </>
    )
}

export default FilterModule
