import React from "react"
import "../_styling/heading.css"

/**
 * A header section, wraps a h2 / p in a hgroup
 * @param {Object} props - The component props
 * @param {string} props.heading - The heading
 * @param {string|undefined} props.subheading - The subheading
 */
const Heading = ({ heading, subheading = undefined }) => {
    return (
        <hgroup className='heading-wrapper'>
            <h2>{heading}</h2>
            {subheading && <p>{subheading}</p>}
        </hgroup>
    )
}

export default Heading
