import React, { useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"

import axios from "../components/axios"
import MetaData, { metaDescription, metaKeywords, metaTitle } from "../components/MetaData"
import SearchProductWrapper from "../components/page-components/search/SearchProductWrapper"
import { defaultSearchFilter } from "../components/page-components/search/SearchFilterBlock"

/**
 * the property page
 */
const PropertyPage = () => {
    const navigate = useNavigate()
    const { propertyId, categoryRoute, subCategoryRoute } = useParams()
    const [searchFilter, setSearchFilter] = useState(null)
    const [propertyValue, setPropertyValue] = useState(null)

    /**
     * Get the property data
     */
    const getDataAsync = async (abortController) => {
        if (!propertyId) return

        try {
            const [resProp, resCategory] = await Promise.all([
                axios.get(`api/properties/value/${propertyId}`, { signal: abortController?.signal }),
                axios.get(`api/category/id-from-route/${categoryRoute}/${subCategoryRoute ?? ""}`, { signal: abortController?.signal }),
            ])

            setPropertyValue(resProp.data)

            if (resProp.status === 200) {
                let categoryId = null
                let subCategoryUuid = null
                if (resCategory.status === 200) {
                    categoryId = resCategory.data?.categoryId ?? categoryId
                    subCategoryUuid = resCategory.data?.subCategoryUuid ?? subCategoryUuid
                }
                setSearchFilter({ ...defaultSearchFilter, properties: [propertyId], categoryId, subCategoryUuid })
            } else {
                navigate("/")
            }
        } catch (e) {
            if (!abortController?.signal?.aborted) console.error(e)
        }
    }

    /**
     * Page init
     */
    useEffect(() => {
        const abortController = new AbortController()
        getDataAsync(abortController)
        return () => abortController.abort()
    }, [propertyId, categoryRoute, subCategoryRoute])

    return (
        <>
            <MetaData
                description={metaDescription(propertyValue)}
                title={metaTitle({ ...propertyValue?.property, subTitle: propertyValue?.name })}
                keywords={metaKeywords({ tags: [propertyValue?.name, propertyValue?.property?.name] })}
            />
            <div className='page-wrapper' id='s-page-top'>
                <div className='content-section-wrapper'>
                    <SearchProductWrapper
                        subTitle={propertyValue?.property?.name ?? ""}
                        searchTitle={propertyValue?.name ?? ""}
                        baseSearchFilter={searchFilter}
                        isParentLoading={searchFilter ? false : true}
                    />
                </div>
            </div>
        </>
    )
}

export default PropertyPage
