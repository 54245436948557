import React, { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import ReactGA from "react-ga4"

import "./_styling/product.css"
import "./_styling/search.css"

import MetaData, { metaDescription, metaKeywords, metaTitle } from "../components/MetaData"
import SearchProductWrapper from "../components/page-components/search/SearchProductWrapper"
import { defaultSearchFilter } from "../components/page-components/search/SearchFilterBlock"

/**
 * The search page
 */
const SearchPage = () => {
    const { searchQuery } = useParams()
    const [searchFilter, setSearchFilter] = useState(null)

    const onResultsUpdated = () => {
        ReactGA.event("search", {
            search_term: searchQuery,
        })
    }

    /**
     * Called when the searchQuery changes
     */
    useEffect(() => {
        setSearchFilter({ ...defaultSearchFilter, searchField: searchQuery ?? "" })
    }, [searchQuery])

    return (
        <>
            <MetaData
                description={metaDescription({ name: searchQuery })}
                title={metaTitle({ name: `Search results for ${searchQuery} ` })}
                keywords={metaKeywords({ tags: [searchQuery] })}
            />
            <div className='page-wrapper' id='s-page-top'>
                <div className='content-section-wrapper'>
                    <SearchProductWrapper
                        baseSearchFilter={searchFilter}
                        onResultsUpdated={onResultsUpdated}
                        subTitle={"Search results for"}
                        searchTitle={searchQuery}
                        isParentLoading={searchFilter ? false : true}
                    />
                </div>
            </div>
        </>
    )
}

export default SearchPage
