import React from "react"

/**
 * The header at the top of category pages
 * @param {Object} props - The component props
 * @param {string} props.heading - The heading
 * @param {string|undefined} props.subheading - The subheading
 * @param {string|undefined} props.backgroundImage - The the background image
 */
function CategoryHeader({ heading, subheading = undefined, backgroundImage }) {
    return (
        <div className='hero' role='banner' style={{ "--_bg-image": `url(${backgroundImage})` }}>
            <hgroup className='hero-content'>
                <h1>{heading}</h1>
                <p>{subheading}</p>
            </hgroup>
        </div>
    )
}

export default CategoryHeader
