import React from "react"

import { ReactComponent as LoadingHomeSvg } from "../assets/ramsdens_house.svg"
import "../pages/_styling/animations.css"

/**
 * loading logo that looks like a home
 */
const LoadingHome = ({ color = "#e30613aa" }) => {
    return (
        <div className='lds-home-wrapper'>
            <LoadingHomeSvg fill={color} />
        </div>
    )
}
export default LoadingHome
