/**
 * Converts a utc date string into a new date object
 * @param {string} utcDateString
 * @returns
 */
export const utcDateStringToUTCDate = (utcDateString) => {
    if (typeof utcDateString === "string") {
        if (!utcDateString.endsWith("Z")) utcDateString += "Z"
    }

    return new Date(utcDateString)
}

/**
 * The date and time (local time) in the local date format
 * @param {string} dateString
 */
export const dateTimeFormat = (dateString) => {
    const dt = new Date(dateString)
    return `${dt.toLocaleDateString()} ${dt.toLocaleTimeString()}`
}

/**
 * The date in the local date format format (dd/mm/yyyy)
 * - dateString should be in the local time format - 2023-08-22T23:00:00
 * @param {string|object} dateString
 */
export const dateFormat = (dateString) => {
    const dt = new Date(dateString)
    return dt.toLocaleDateString()
}

/**
 * The date and time (local time) in the local date format from a utc date string
 * @param {string|object} utcDateString
 */
export const dateTimeFormatUTC = (utcDateString) => {
    const dt = utcDateStringToUTCDate(utcDateString)
    return `${dt.toLocaleDateString()} ${dt.toLocaleTimeString()}`
}

/**
 * The date in the local date format format (dd/mm/yyyy) from a utc date string
 * - utcDateString should be in the UTC time format - 2023-08-22T23:00:00Z
 * @param {string|object} utcDateString
 */
export const dateFormatUTC = (utcDateString) => {
    const dt = utcDateStringToUTCDate(utcDateString)
    return dt.toLocaleDateString()
}

/**
 * If the current date is within the passed date strings
 * @param {string|object} fromDate
 * @param {string|object} toDate
 */
export const isBetweenDates = (fromDate, toDate) => {
    const from = new Date(fromDate)
    const to = new Date(toDate)
    const check = new Date()

    return check > from && check < to
}

export const isBetweenDatesUTC = (fromDate, toDate) => {
    //TODO convert fromDate / toDate to UTC
    return isBetweenDates(fromDate, toDate)
}

/**
 * Gets the end of the day (local time)
 * @param {string|object} date
 */
export const endOfTheDay = (date) => {
    var end = new Date(typeof date === "object" ? date.getTime() : date)
    end.setHours(23, 59, 59, 999)
    return end
}

/**
 * Gets the start of the day (local time)
 * @param {string|object} date
 */
export const startOfTheDay = (date) => {
    var start = new Date(typeof date === "object" ? date.getTime() : date)
    start.setHours(0, 0, 0, 0)
    return start
}

/*
 * Gets the end of the day (UTC)
 * @param {string|object} date
 */
export const endOfTheDayUTC = (date) => {
    var end = new Date(typeof date === "object" ? date.getTime() : date)
    end.setUTCHours(23, 59, 59, 999)
    return end
}

/**
 * Gets the start of the day (UTC)
 * @param {string|object} date
 */
export const startOfTheDayUTC = (date) => {
    var start = new Date(typeof date === "object" ? date.getTime() : date)
    start.setUTCHours(0, 0, 0, 0)
    return start
}

/**
 * Gets the valid until date for the price
 */
export const priceValidUntilDate = (hasPrice, price, salePrice) => {
    let validDate = new Date() // Today
    validDate.setHours(0, 0, 0, 0)

    if (hasPrice) {
        if (salePrice && salePrice > 0) validDate.setDate(validDate.getDate() + 7)
        else if (price && price > 0) validDate.setDate(validDate.getDate() + 30)
    }

    return validDate.toISOString()
}
