import React from "react"
import { Link } from "react-router-dom"
import { motion } from "framer-motion"

import "../../pages/_styling/products.css"
import ImmediateDelivery from "../../assets/immediate-delivery.svg"
import NoProductImage from "../../assets/no-image.png"
import { getRouteName } from "../utility/RouteHelpers"
import { formatPrice } from "../utility/UsefulFunctions"
import { priceValidUntilDate } from "../utility/ConvertTime"

/**
 * A single product
 */
const ProductRowCard = ({
    uuid,
    tags,
    name,
    thumbnailUrl,
    secondThumbnailUrl,
    brandName,
    variantAmount = 1,
    index = -1,
    onSale = false,
    salePrice = 0,
    price = 0,
    showCatPill = false,
    isDragging = false,
    isCarouselItem = true,
    moreColorsAvailable = false,
    immediateDelivery = false,
}) => {
    if (!thumbnailUrl) thumbnailUrl = NoProductImage

    // if we have the immediateDelivery flag or a valid price
    const hasPrice = price ? price > 0 : false
    const immediateDeliveryOrPrice = immediateDelivery || hasPrice

    return (
        <Link
            to={`/product/${uuid}/${getRouteName(name)}`}
            className={`product-card-wrapper flex-column flex-gap-none h-product u-url ${isCarouselItem ? "hp-carousel-item" : ""}`}
            onClick={(e) => {
                if (isDragging) {
                    e.preventDefault()
                }
            }}
            draggable={false}
            role='listitem'
            itemProp='itemListElement'
            itemType='https://schema.org/Product'
            itemScope
        >
            <meta itemProp='name' content={name} />
            <link itemProp='image' href={thumbnailUrl} />

            <div className='pc-image' draggable={false}>
                <motion.img
                    whileHover={{
                        scale: 1.05,
                        transition: { duration: 0.1 },
                    }}
                    src={thumbnailUrl}
                    onMouseOver={(e) => {
                        if (secondThumbnailUrl) e.currentTarget.src = secondThumbnailUrl
                    }}
                    onMouseOut={(e) => (e.currentTarget.src = thumbnailUrl)}
                    alt={name}
                    className='u-photo cover'
                    loading='lazy'
                    draggable={false}
                    crossOrigin='anonymous'
                    referrerPolicy='same-origin'
                />
                {showCatPill && (
                    <div className='subcat-pill'>
                        <p>{tags[2] ? tags[2] : tags[1]}</p>
                    </div>
                )}
                {onSale && <OnSalePill index={index} salePrice={salePrice} wasPrice={price} />}
            </div>
            <div className='flex-column flex-gap-16 product-card-info'>
                <div className='flex-column flex-gap-2'>
                    <div className='flex-column flex-gap-2'>
                        <div className='product-brand-name' itemProp='brand' itemType='https://schema.org/Brand' itemScope>
                            <p itemProp='name'>{brandName}</p>
                        </div>
                        <div className='pc-name p-name'>
                            <p>{name}</p>
                        </div>
                    </div>
                </div>
                <div className='flex-column flex-gap-2 full-width' itemProp='offers' itemScope itemType='https://schema.org/Offer'>
                    <meta itemProp='name' content={name} />
                    <meta itemProp='availability' content={hasPrice ? "https://schema.org/InStock" : "https://schema.org/InStoreOnly"} />
                    <meta itemProp='itemCondition' content='https://schema.org/NewCondition' />
                    <meta itemProp='priceCurrency' content='GBP' />
                    <meta itemProp='price' content={hasPrice ? (salePrice ? salePrice : price) : 99999} />
                    <meta itemProp='priceValidUntil' content={priceValidUntilDate(hasPrice, price, salePrice)} />

                    {immediateDeliveryOrPrice ? (
                        <div className='product-card-extra-info'>
                            <div className='immediate-delivery'>
                                {immediateDelivery ? (
                                    <img src={ImmediateDelivery} crossOrigin='anonymous' referrerPolicy='same-origin' alt='immediate delivery' />
                                ) : (
                                    <span></span>
                                )}
                            </div>
                            <div>
                                {hasPrice ? (
                                    <p className={`product-card-price ${onSale ? "product-card-sales-price" : ""}`}>
                                        From {onSale ? formatPrice(salePrice) : formatPrice(price)}
                                    </p>
                                ) : null}{" "}
                            </div>
                        </div>
                    ) : null}
                    <div className='product-card-extra-info'>
                        <div className='more-options-image'>
                            {moreColorsAvailable ? (
                                <img
                                    src='https://rsazure.blob.core.windows.net/rhi-images/other%20options%20available.svg'
                                    crossOrigin='anonymous'
                                    referrerPolicy='same-origin'
                                    alt='more options available'
                                />
                            ) : (
                                <span> </span>
                            )}
                        </div>
                        <div>
                            {variantAmount > 1 ? (
                                <p>
                                    {variantAmount} Item{variantAmount > 1 && "s"} Inside
                                </p>
                            ) : null}
                        </div>
                    </div>
                </div>
            </div>
        </Link>
    )
}

function OnSalePill({ _index = -1, salePrice = 0 }) {
    // If the sale price is undefined or 0 then dont display it
    if (!salePrice || salePrice <= 0) return <></>

    // was <div className='offer-pill'>{index % 2 === 0 || salePrice <= 0 ? "On Sale" : `From ${formatPrice(salePrice)}`}</div>
    return <div className='offer-pill'>{`From ${formatPrice(salePrice)}`}</div>
}

export default ProductRowCard
