import { Skeleton } from "@mui/material"
import React from "react"

/**
 * Helper function for SkeletonWrapper
 */
export const skeletonWrapperArray = ({ count = 1, width = undefined, height = undefined, variant = "text", style = {}, callback = undefined }) => {
    const sx = { bgcolor: "grey.200", ...style }
    const skeletons = []

    // Buuild the skeletons
    for (let i = 0; i < count; i++) {
        let skeleton = <Skeleton variant={variant} sx={sx} width={width} height={height} />
        if (callback) skeleton = callback(skeleton, i)

        skeletons.push(<React.Fragment key={i}>{skeleton}</React.Fragment>)
    }

    return skeletons
}

/**
 * Wrapper to help build skeletons
 * @param {Function} callback(skeleton, index)
 */
function SkeletonWrapper({ count = 1, width = undefined, height = undefined, variant = "text", style = {}, callback = undefined }) {
    const skeletons = skeletonWrapperArray({ count, width, height, variant, style, callback })

    return (
        <>
            {skeletons.map((value) => {
                return value
            })}
        </>
    )
}

export default SkeletonWrapper
