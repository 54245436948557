import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom"

import axios from "../axios"

import InterestFreeCreditLandscape from "../../assets/interest-free-credit-landscape.png"
import useSiteData from "../../hooks/useSiteData"

/**
 * Navbar range icon
 */
const NavbarRange = ({ onClick = undefined, isVisible = false, categoryId = 0 }) => {
    const { showInterestFreeCredit } = useSiteData()
    const [isLoaded, setIsLoaded] = useState(false)
    const [navbarRange, setNavbarRange] = useState(null)

    /**
     * Get the data
     */
    const getRange = async (categoryId, abortController) => {
        if (isLoaded) return
        setIsLoaded(false)
        try {
            const res = await axios.get(`/api/range/navbar/${categoryId}`, { signal: abortController?.signal })
            setNavbarRange(res.data)
        } catch (e) {
            if (!abortController?.signal?.aborted) console.error(e)
        } finally {
            setIsLoaded(true)
        }
    }

    /**
     * Init range
     */
    useEffect(() => {
        const abortController = new AbortController()
        if (isVisible && !isLoaded) getRange(categoryId, abortController)
        return () => abortController.abort()
    }, [isVisible, categoryId])

    /**
     * If the categoryId changes
     */
    useEffect(() => {
        setNavbarRange(null)
        setIsLoaded(false)
    }, [categoryId])

    return (
        <div className='navbar-range-wrapper'>
            {navbarRange ? (
                <Link key={navbarRange.uuid} to={`/range/${navbarRange.routeName}`} onClick={onClick} className='navbar-range'>
                    <img src={navbarRange.imageUrl} crossOrigin='anonymous' referrerPolicy='same-origin' alt='Product Range' />
                    <h3>{navbarRange.name}</h3>
                </Link>
            ) : showInterestFreeCredit ? (
                <img
                    src={InterestFreeCreditLandscape}
                    crossOrigin='anonymous'
                    referrerPolicy='same-origin'
                    alt='Interest Free Credit Available'
                    className='navbar-range-default-img'
                />
            ) : null}
        </div>
    )
}

export default NavbarRange
