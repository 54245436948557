import Divider from "../Divider"
import ProductRow from "./ProductRow"
import RowSection from "./RowSection"
import SectionHeading from "./SectionHeading"

/**
 * A product row from the front page
 */
function ProductRowSection({ category, heading, subheading, linkTo, products, divider = true, isLoading = false }) {
    if (!isLoading) {
        if (!products) return <></>
        if (!Array.isArray(products)) return <></>
        if (products.length === 0) return <></>
    }

    return (
        <>
            <RowSection>
                <SectionHeading category={category} heading={heading} subheading={subheading} linkTo={linkTo} />
                <ProductRow products={products} isLoading={isLoading} />
            </RowSection>
            {divider && <Divider padding={16} percent={62} />}
        </>
    )
}

export default ProductRowSection
