/**
 * Creates the routen name from the passed name
 */
export function getRouteName(name) {
    if (typeof name !== "string" || name === "") return ""

    const charIsValid = (str) => {
        return str.length === 1 && str.match(/[a-z0..9]/i)
    }

    name = name.toLowerCase().trim()

    var sb = ""
    for (const c of name) {
        if (c === " " || c === "-" || c === "_" || c === "+") sb += "-"
        else if (charIsValid(c)) sb += c
    }

    return sb
        .replaceAll("--", "-")
        .replaceAll("--", "-")
        .replace(/^-+|-+$/gi, "")
}
