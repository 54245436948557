import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom"

import axios from "../../axios"
import RowSection from "../RowSection"
import SectionHeading from "../SectionHeading"
import ProductRow from "../ProductRow"
import Divider from "../../Divider"

/**
 * The no-results block to show when we dont have any search results
 * @param {Object} props - The component props
 * @param {string|undefined} props.searchTitle - The title for this search
 * @param {string|undefined} props.subTitle - The subtitle to show before the search title
 */
function NoSearchResultsBlock({ subTitle = undefined, searchTitle = undefined }) {
    const [newIn, setNewIn] = useState(null)

    /**
     * Get whats new in
     */
    const getNoResultsData = async (abortController) => {
        try {
            const res = await axios.get("/api/search/newin", { signal: abortController?.signal })
            setNewIn(res.data)
        } catch (e) {
            if (!abortController?.signal?.aborted) console.error(e)
        }
    }

    /**
     * Called after a search
     */
    useEffect(() => {
        const abortController = new AbortController()
        getNoResultsData(abortController)
        return () => abortController.abort()
    }, [])

    return (
        <div className='search-no-items'>
            <p>
                No {subTitle || searchTitle ? `${subTitle ?? ""} ${searchTitle ?? ""}` : "products found"} <br />
                <br />
                <span>
                    Why not{" "}
                    <Link to='/contact' className='red-text'>
                        call us
                    </Link>{" "}
                    or browse some of our selections below.
                </span>
            </p>
            <Divider percent={50} />
            <div className='full-width'>
                {newIn && (
                    <RowSection>
                        <SectionHeading
                            category='New In'
                            heading="Why not see what's New In instead?"
                            subheading='Elevate your home with our newest arrivals, carefully curated to bring fresh inspiration and style to every corner.'
                            linkTo='/latest-products'
                        />
                        <ProductRow heading='New In' products={newIn} />
                    </RowSection>
                )}
            </div>
        </div>
    )
}

export default NoSearchResultsBlock
