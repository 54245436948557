export function debounce(func, timeout = 300) {
    let timer
    return (...args) => {
        clearTimeout(timer)
        timer = setTimeout(() => {
            func.apply(this, args)
        }, timeout)
    }
}

/**
 * converts the passed string into a slug
 * @param {string} value
 */
export function urlSlug(value) {
    const lc = value
        .trim()
        .toLocaleLowerCase()
        .replaceAll(" ", "-")
        .replace("_", "-")
        .replace(/[^a-z0-9-]+/gi, "")
    return encodeURIComponent(lc)
}

/**
 * formats the price into the correct format
 * note: this doent not add the outer schema - itemScope itemType='https://schema.org/Offer'
 * @param {number} value the number to display as a price
 * @returns the price formated in GBP
 */
export function formatPrice(value) {
    const formatter = new Intl.NumberFormat("en-GB", {
        style: "currency",
        currency: "GBP",
        trailingZeroDisplay: "stripIfInteger",
    })

    return formatter.format(value)
}
