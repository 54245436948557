import React, { useState } from "react"
import Carousel from "react-multi-carousel"

import "../../pages/_styling/category.css"

import ProductRowCard from "./ProductRowCard"
import { skeletonWrapperArray } from "../mui/SkeletonWrapper"

const ProductRow = ({ products, isLoading = false }) => {
    const [isDragging, setIsDragging] = useState(false)

    const responsive = {
        desktopLarge: {
            breakpoint: { max: Number.MAX_SAFE_INTEGER, min: 1600 },
            items: 5,
            partialVisibilityGutter: 30,
        },
        desktop: {
            breakpoint: { max: 1600, min: 1200 },
            items: 4,
        },
        tablet: {
            breakpoint: { max: 1200, min: 850 },
            items: 3,
        },
        mobile2: {
            breakpoint: { max: 850, min: 400 },
            items: 2,
        },
        mobile: {
            breakpoint: { max: 400, min: 0 },
            items: 1.5,
        },
    }

    if (!products && !isLoading) return null

    return (
        <div className='hp-product-row-wrapper '>
            {products || isLoading ? (
                <Carousel
                    containerClass='carousel-container'
                    itemClass='carousel-item'
                    arrows={true}
                    autoPlaySpeed={3000}
                    centerMode={false}
                    responsive={responsive}
                    swipeable
                    draggable
                    infinite
                    beforeChange={() => setIsDragging(true)}
                    afterChange={() => setIsDragging(false)}
                    role='list'
                    itemScope
                    itemType='https://schema.org/ItemList'
                >
                    {isLoading
                        ? skeletonWrapperArray({
                              count: 4,
                              variant: "rounded",
                              width: "95%",
                              height: 400,
                              callback: (skeleton) => <div className='hp-carousel-item'>{skeleton}</div>,
                          })
                        : products.map((c, index) => {
                              return (
                                  <ProductRowCard
                                      key={c.uuid}
                                      uuid={c.uuid}
                                      tags={c.tags}
                                      name={c.name}
                                      onSale={c.onSale}
                                      salePrice={c.salePrice}
                                      price={c.price}
                                      index={index}
                                      thumbnailUrl={c.thumbnailUrl[0]}
                                      secondThumbnailUrl={c.thumbnailUrl[1]}
                                      brandName={c.brandName}
                                      variantAmount={c.variantAmount}
                                      isDragging={isDragging}
                                      moreColorsAvailable={c.moreColorsAvailable ?? false}
                                      immediateDelivery={c.immediateDelivery ?? false}
                                  />
                              )
                          })}
                </Carousel>
            ) : null}
        </div>
    )
}

export default ProductRow
