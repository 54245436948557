import React, { useEffect, useRef, useState } from "react"
import ReactGA from "react-ga4"
import Cookies from "js-cookie"
import { Outlet, useLocation } from "react-router-dom"

import "./_styling/layout.css"

import MetaData, { metaDescription, metaKeywords, metaTitle } from "./MetaData"
import Footer from "./Footer"
import NavBar from "./navigation/NavBar"
import CookiePopup from "./CookiePopup"

const Layout = () => {
    const loc = useLocation()
    const topContainer = useRef()

    const [hasChosenCookiePref, setHasChosenCookiePref] = useState(true)

    /**
     * Google cookies accepted
     */
    const handleAcceptCookie = () => {
        if (process.env.REACT_APP_GA_MEASUREMENT_ID && process.env.REACT_APP_GA_MEASUREMENT_ID != 0) {
            ReactGA.initialize(process.env.REACT_APP_GA_MEASUREMENT_ID)
            console.log("here")
        }
        Cookies.set("cookie_consent", "true", { expires: 365, sameSite: "strict" })
        setHasChosenCookiePref(true)
    }

    /**
     * Remove all google cookies
     */
    const handleDeclineCookie = () => {
        Cookies.set("cookie_consent", "false", { expires: 1, sameSite: "strict" })
        Cookies.remove("_ga")
        Cookies.remove("_gat")
        Cookies.remove("_gid")
        setHasChosenCookiePref(true)
    }

    /**
     *  Below useEffect scrolls to the top of the page when a new page is loaded.
     * This is due to React (for some reason) transferring the scroll position on page change.
     */
    useEffect(() => {
        topContainer.current.scrollIntoView({ block: "end", behaviour: "smooth" })
    }, [loc.pathname])

    /**
     * Called when the page first loads
     */
    useEffect(() => {
        const isConsent = Cookies.get("cookie_consent")
        if (isConsent === "true") {
            handleAcceptCookie()
        } else {
            setHasChosenCookiePref(Boolean(isConsent))
        }
    }, [])

    return (
        <>
            <MetaData description={metaDescription()} title={metaTitle()} keywords={metaKeywords()} />
            <div className='layout-wrapper'>
                <NavBar />
                <div className='layout-content-wrapper'>
                    <div ref={topContainer} />
                    <Outlet />
                    <Footer />
                </div>
            </div>
            {!hasChosenCookiePref && <CookiePopup accept={handleAcceptCookie} decline={handleDeclineCookie} />}
        </>
    )
}

export default Layout
