import * as React from "react"

import Dialog from "@mui/material/Dialog"
import DialogContent from "@mui/material/DialogContent"
import DialogTitle from "@mui/material/DialogTitle"
import { IconButton, Slide } from "@mui/material"
import CloseIcon from "@mui/icons-material/Close"

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction='down' ref={ref} {...props} />
})

export default function PopupDialog({ isOpen, title = undefined, onClose, fullWidth = false, maxWidth = "lg", children }) {
    return (
        <Dialog open={isOpen} onClose={onClose} maxWidth={maxWidth} fullWidth={fullWidth} TransitionComponent={Transition}>
            <DialogTitle id='alert-dialog-title'>{title}</DialogTitle>
            {
                <IconButton
                    aria-label='close'
                    onClick={onClose}
                    sx={{
                        position: "absolute",
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            }
            <DialogContent>{children}</DialogContent>
        </Dialog>
    )
}
