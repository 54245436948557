import { useState } from "react"
import { useSearchParams } from "react-router-dom"
import Select from "react-select"
import { Switch } from "@mui/material"

import FilterModule from "./FilterModule"
import Divider from "../../Divider"

export const sortByOptions = [
    {
        label: "A-Z",
        value: "az",
    },
    {
        label: "Z-A",
        value: "za",
    },
    {
        label: "Newest",
        value: "recent",
    },
    {
        label: "Oldest",
        value: "oldest",
    },
]

export const defaultSearchFilter = {
    searchField: null,
    categoryId: null,
    subCategoryUuid: null,
    brandUuid: null,
    rangeUuid: null,
    onSaleOnly: false,
    newProductsOnly: false,
    sortBy: sortByOptions[0].value,
    properties: [],
}

/**
 * Gets the react select option
 */
export const findReactSelectOption = (options, value) => {
    if (!Array.isArray(options)) return undefined
    return options?.find((c) => c.value === value) ?? undefined
}

/**
 * The search filter block
 */
export default function SearchFilterBlock({
    showFilter = true,
    searchFilter,
    setSearchFilter,
    searchFilterOptions = undefined,
    baseSearchFilter = defaultSearchFilter,
}) {
    const [searchParams, setSearchParams] = useSearchParams()
    const [isOpen, setIsOpen] = useState(false)

    const toggleIsOpen = () => {
        setIsOpen((currentValue) => !currentValue)
    }

    /**
     * Updates the search value
     */
    const updateSearchFilter = (name, value, nulledField = "_stub") => {
        setSearchFilter((currentValue) => {
            return { ...currentValue, [name]: value, [nulledField]: null }
        })

        searchParams.delete("page")
        setSearchParams({ ...searchParams })
    }

    /**
     * Gets the react select options
     */
    const findReactSelectMultiOption = (options, values) => {
        if (!Array.isArray(values)) return undefined
        if (!Array.isArray(options)) return undefined
        return options?.filter((c) => values.includes(c.value)) ?? undefined
    }

    /**
     * Check box changed
     */
    const onCheckboxChanged = (e) => {
        const name = e.target.id
        const isChecked = e.target.checked ? true : false
        updateSearchFilter(name, isChecked)
    }

    /**
     * React selection changed
     * @param {string} id
     * @param {object} e
     */
    const onReactSelectChange = (id, e) => {
        let newValue = null
        if (Array.isArray(e)) {
            newValue = []
            e.forEach((v) => newValue.push(v.value))
        } else if (e) {
            newValue = e.value
        }

        // If categoryId changes we need to null subCategoryUuid
        const nulledField = id === "categoryId" ? "subCategoryUuid" : "blankField"

        updateSearchFilter(id, newValue, nulledField)
    }

    /**
     * React selection changed
     * @param {string} id
     * @param {array} e
     */
    const onReactSelectMultiChange = (id, e, actionMeta) => {
        // Add any new items
        let currentArray = [...searchFilter[id]]
        if (Array.isArray(e)) {
            e.forEach((v) => currentArray.push(v.value))
        } else if (e) {
            currentArray.push(e.value)
        }

        // Remove the removed items
        const removedIds = []
        if (actionMeta.removedValues) {
            actionMeta.removedValues.forEach((v) => removedIds.push(v.value))
        }
        if (actionMeta.removedValue) {
            removedIds.push(actionMeta.removedValue.value)
        }
        if (removedIds.length > 0) {
            currentArray = currentArray.filter((v) => !removedIds.includes(v))
        }

        updateSearchFilter(id, Array.from(new Set(currentArray)))
    }

    /**
     * Reset the filters
     */
    const clearFiltersClick = () => {
        setSearchFilter({ ...baseSearchFilter })
    }

    if (!showFilter) return <></>
    if (!searchFilterOptions) return <></>

    return (
        <FilterModule isOpen={isOpen} toggleIsOpen={toggleIsOpen}>
            <div className='filter-sections flex-column flex-gap-16'>
                <div className='filter-wrapper'>
                    <label htmlFor='categoryId'>Category</label>
                    <Select
                        inputId='categoryId'
                        className='a-select'
                        value={findReactSelectOption(searchFilterOptions?.categoryFilters, searchFilter?.categoryId)}
                        options={searchFilterOptions?.categoryFilters ?? undefined}
                        onChange={(e) => onReactSelectChange("categoryId", e)}
                        isClearable={true}
                        isDisabled={baseSearchFilter?.categoryId !== null}
                    />
                </div>

                {searchFilter?.categoryId && (
                    <div className='filter-wrapper'>
                        <label htmlFor='subCategoryUuid'>Sub-Category</label>
                        <Select
                            inputId='subCategoryUuid'
                            className='a-select'
                            value={findReactSelectOption(searchFilterOptions?.subCategoryFilters, searchFilter?.subCategoryUuid)}
                            options={searchFilterOptions?.subCategoryFilters ?? undefined}
                            onChange={(e) => onReactSelectChange("subCategoryUuid", e)}
                            isDisabled={!searchFilter?.categoryId || baseSearchFilter?.subCategoryUuid !== null}
                            isClearable={true}
                        />
                    </div>
                )}

                <div className='filter-wrapper'>
                    <label htmlFor='brandUuid'>Brands</label>
                    <Select
                        inputId='brandUuid'
                        className='a-select'
                        value={findReactSelectOption(searchFilterOptions?.brandFilters, searchFilter?.brandUuid)}
                        options={searchFilterOptions?.brandFilters ?? undefined}
                        onChange={(e) => onReactSelectChange("brandUuid", e)}
                        isClearable={true}
                        isDisabled={baseSearchFilter?.brandUuid !== null}
                    />
                </div>

                {searchFilterOptions?.propertyFilters?.map((property) => {
                    if (!property.items) return
                    const id = "properties-" + property.value
                    return (
                        <div className='filter-wrapper' key={property.value}>
                            <label htmlFor={id}>{property.label}</label>
                            <Select
                                inputId={id}
                                className='a-select'
                                value={findReactSelectMultiOption(property.items, searchFilter?.properties)}
                                options={property.items}
                                onChange={(e, actionMeta) => onReactSelectMultiChange("properties", e, actionMeta)}
                                isMulti
                            />
                        </div>
                    )
                })}

                <div className='filter-wrapper'>
                    Special Offers
                    <br />
                    <label htmlFor='onSaleOnly'>
                        {searchFilter?.onSaleOnly ? "Offers Only" : "All Products"}
                        <Switch id='onSaleOnly' name='onSaleOnly' checked={searchFilter?.onSaleOnly} onChange={onCheckboxChanged} />
                    </label>
                </div>

                <div className='filter-wrapper'>
                    New Products
                    <br />
                    <label htmlFor='newProductsOnly'>
                        {searchFilter?.newProductsOnly ? "New In" : "All Products"}
                        <Switch id='newProductsOnly' name='newProductsOnly' checked={searchFilter?.newProductsOnly} onChange={onCheckboxChanged} />
                    </label>
                </div>

                <div className='filter-wrapper'>
                    <label htmlFor='sortBy'>Sort By</label>
                    <Select
                        inputId='sortBy'
                        className='a-select'
                        value={findReactSelectOption(sortByOptions, searchFilter?.sortBy)}
                        options={sortByOptions}
                        onChange={(e) => onReactSelectChange("sortBy", e)}
                    />
                </div>

                <div className='filter-wrapper'>
                    <button onClick={toggleIsOpen} className='button-contained button-100'>
                        View Results
                    </button>
                </div>

                <Divider padding={8} />

                <div className='filter-wrapper'>
                    <button onClick={clearFiltersClick} className='button-link text-small button-100'>
                        Clear Filters
                    </button>
                </div>
            </div>
        </FilterModule>
    )
}
