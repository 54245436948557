import React from "react"

/**
 * Select / drop down for a form
 * options = [{value:1, label: 'Option1', value:2, label: 'Option2'}]
 */
function InputSelect({
    options = [],
    id,
    defaultValue,
    onChange = undefined,
    required = false,
    disabled = false,
    multiple = false,
    placeholder = "Select...",
}) {
    return (
        <select
            id={id}
            name={id}
            onChange={onChange}
            required={required}
            multiple={multiple}
            className='rs-input rs-select'
            defaultValue={defaultValue ?? undefined}
            value={defaultValue ?? undefined}
            disabled={disabled}
            placeholder={placeholder}
        >
            {options?.map((option) => {
                return (
                    <option key={option.value} value={option.value}>
                        {option.label}
                    </option>
                )
            })}
        </select>
    )
}

export default InputSelect
