import React, { useEffect, useState } from "react"
import { motion } from "framer-motion"
import KeyboardDoubleArrowDownIcon from "@mui/icons-material/KeyboardDoubleArrowDown"
import KeyboardDoubleArrowUpIcon from "@mui/icons-material/KeyboardDoubleArrowUp"
import MarkdownWrapper from "../MarkdownWrapper"

const ProductDescription = ({ description, variantDescription }) => {
    const [toExpand, setToExpand] = useState(false)
    const [expandText, setExpandText] = useState(false)
    const [combinedText, setCombinedText] = useState(null)

    useEffect(() => {
        if (description && variantDescription) {
            let descLen = description.length
            let variantLen = variantDescription.length
            let totalLen = descLen + variantLen

            if (totalLen > 900) {
                setToExpand(true)
            } else {
                setToExpand(false)
            }

            setCombinedText(description + "  \n\n" + variantDescription)
        } else if (description) {
            let descLen = description.length

            if (descLen > 900) {
                setToExpand(true)
            } else {
                setToExpand(false)
            }
            setCombinedText(description)
        }
    }, [description, variantDescription])

    return (
        <>
            {combinedText && (
                <motion.div
                    initial={{ opacity: 0, y: 50 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.5, delay: 0.2 }}
                    className='p-description e-description'
                >
                    <MarkdownWrapper>{expandText ? combinedText : toExpand ? combinedText.slice(0, 900) + "..." : combinedText}</MarkdownWrapper>
                </motion.div>
            )}
            {toExpand === true && (
                <>
                    {expandText ? (
                        <div className='show-more-wrapper'>
                            <p
                                className='icon-faded flex-row-center-vertical'
                                onClick={() => {
                                    setExpandText(false)
                                    document.getElementById("product-top").scrollIntoView()
                                }}
                            >
                                <KeyboardDoubleArrowUpIcon /> Show Less
                            </p>
                        </div>
                    ) : (
                        <div className='show-more-wrapper'>
                            <p className='icon-faded flex-row-center-vertical' onClick={() => setExpandText(true)}>
                                <KeyboardDoubleArrowDownIcon /> Show More
                            </p>
                        </div>
                    )}
                </>
            )}
        </>
    )
}

export default ProductDescription
