import React, { useEffect, useState } from "react"
import ReCAPTCHA from "react-google-recaptcha"
import ReactGA from "react-ga4"
import { useParams } from "react-router-dom"

import "./_styling/contact.css"

import axios from "../components/axios"
import MetaData, { metaDescription, metaKeywords, metaTitle } from "../components/MetaData"
import InputSelect from "../components/InputSelect"
import ProductRowSection from "../components/page-components/ProductRowSection"
import Divider from "../components/Divider"

const Contact = () => {
    const { variantUuid } = useParams()

    const [name, setName] = useState("")
    const [email, setEmail] = useState("")
    const [description, setDescription] = useState("")
    const [categoryChoice, setCategoryChoice] = useState(null)
    const [captchaValue, setCaptchaValue] = useState(null)
    const [categories, setCategories] = useState(null)
    const [hpProducts, setHpProducts] = useState(null)

    const [formSent, setFormSent] = useState(false)
    const [isFormValid, setIsFormValid] = useState(false)

    const getInitialData = async () => {
        const hpSections = await axios.get("/api/search/hp-sections")
        setHpProducts(hpSections.data)
    }

    /**
     * Form submition
     */
    const submitContactForm = async (e) => {
        e.preventDefault()
        if (!isFormValid) return

        setIsFormValid(false)
        try {
            const res = await axios.post("/api/contact", {
                name,
                email,
                categoryId: categoryChoice ?? 0,
                description,
                captchaValue,
                variantUuid,
            })
            ReactGA.event("generate_lead")

            if (res?.status === 200) {
                setName("")
                setEmail("")
                setDescription("")
                setCategoryChoice(null)
                setFormSent(true)
            }
        } finally {
            setIsFormValid(true)
        }
    }

    const presetInfo = async (abortController) => {
        try {
            const [productRes, categoryRes] = await Promise.all([
                axios.get(`/api/product/variant/${variantUuid}`, { signal: abortController?.signal }),
                axios.get(`/api/category/minlist`, { signal: abortController?.signal }),
            ])
            if (categoryRes.status == 200) {
                const catOptions = categoryRes.data
                setCategories([{ value: 0, label: "General Inquiry" }, ...catOptions])
            }

            if (productRes.status == 200) {
                setDescription(
                    `Hello, I am interested in your product "${productRes.data.productName}", specifically the variant "${productRes.data.name}". \n\nCan you please provide me with some more information. \n\nMany Thanks.`
                )
            }

            // Set the default cat in the drop down
            if (categoryRes.status == 200 && productRes.status == 200) {
                const categoryId = productRes.data.categoryId
                if (categoryId && categoryId > 0) {
                    const selectedCat = categoryRes.data.find((element) => element.value === categoryId)
                    setCategoryChoice(selectedCat?.value)
                }
            }
        } catch (e) {
            if (!abortController?.signal?.aborted) console.error(e)
        }
    }

    const onCaptchaChange = (value) => {
        setCaptchaValue(value)
    }

    /**
     * Form update
     */
    useEffect(() => {
        if (name && email && description && captchaValue) setIsFormValid(true)
        else setIsFormValid(false)
    }, [name, email, description, categoryChoice, captchaValue])

    /**
     * Form sent update
     */
    useEffect(() => {
        if (formSent) {
            getInitialData()
        }
    }, [formSent])

    /**
     * Page load
     */
    useEffect(() => {
        const abortController = new AbortController()
        presetInfo(abortController)
        return () => abortController.abort()
    }, [])

    return (
        <>
            <MetaData
                description={metaDescription({ name: "Contact Us" })}
                title={metaTitle({ name: "Contact Us" })}
                keywords={metaKeywords({ tags: ["contact", "email", "phone"] })}
                scripts={["https://www.google.com/recaptcha/api.js"]}
            />
            <div className='page-wrapper'>
                {formSent ? (
                    <div className='flex-column full-width flex-center-item'>
                        <div className='flex-column full-width flex-center-item margin-top-32'>
                            <h3>Thank You For Contacting Us</h3>
                            <p>Your message has been received successfully.</p>
                            <p>A member of our team will be in contact.</p>
                        </div>
                        <Divider padding={16} percent={62} />
                        <div className='hp-product-rows-wrapper'>
                            <ProductRowSection
                                category='Special Offers'
                                heading='Available for a Limited Time Only'
                                subheading='Breathe new life into your home with stunning savings on sofas, beds, and more!'
                                linkTo='/special-offers'
                                products={hpProducts?.offers}
                                divider={false}
                            />
                        </div>
                    </div>
                ) : (
                    <div className='content-section-wrapper-sm'>
                        <hgroup>
                            <h1>Contact Us</h1>
                            <p className='light-text'>
                                You can also reach us during our open hours by phone:{" "}
                                <b>
                                    <a href={`tel:${process.env.REACT_APP_PHONE_LINK}`}>{process.env.REACT_APP_PHONE}</a>
                                </b>
                            </p>
                        </hgroup>
                        <Divider padding={16} />
                        <form id='contact-form' className='flex-column flex-gap-16 full-width padding-16-0' onSubmit={submitContactForm}>
                            <div className='flex-column flex-gap-none a-input-wrapper full-width'>
                                <label htmlFor='usersName'>Name *</label>
                                <input
                                    id='usersName'
                                    name='usersName'
                                    autoComplete='name'
                                    type='text'
                                    value={name}
                                    required
                                    onChange={(e) => setName(e.target.value)}
                                />
                            </div>
                            <div className='flex-column flex-gap-none a-input-wrapper full-width'>
                                <label htmlFor='usersEmail'>Email *</label>
                                <input
                                    id='usersEmail'
                                    name='usersEmail'
                                    autoComplete='email'
                                    type='email'
                                    value={email}
                                    required
                                    onChange={(e) => setEmail(e.target.value)}
                                />
                            </div>
                            <div className='flex-column flex-gap-none a-input-wrapper full-width'>
                                <label htmlFor='department'>Department *</label>
                                <InputSelect
                                    id='department'
                                    name='department'
                                    defaultValue={categoryChoice}
                                    className='contact-select'
                                    options={categories ?? undefined}
                                    onChange={(e) => setCategoryChoice(e.target.value)}
                                />
                            </div>
                            <div className='flex-column flex-gap-none a-input-wrapper full-width'>
                                <label htmlFor='description'>Description *</label>
                                <textarea
                                    id='description'
                                    name='description'
                                    type='text'
                                    value={description}
                                    rows={8}
                                    onChange={(e) => setDescription(e.target.value)}
                                />
                            </div>
                            <ReCAPTCHA sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY} onChange={onCaptchaChange} />
                            <button type='submit' className='button-contained full-width-sm' disabled={!isFormValid}>
                                Send Request
                            </button>
                        </form>
                    </div>
                )}
            </div>
        </>
    )
}

export default Contact
