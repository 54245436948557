import React, { useState } from "react"
import { motion } from "framer-motion"
import Carousel from "react-multi-carousel"
import { Link } from "react-router-dom"

import TrendingFlatIcon from "@mui/icons-material/TrendingFlat"

import "../../pages/_styling/category.css"
import "react-multi-carousel/lib/styles.css"

import SpecialOffersThumbnail from "../../assets/special-offers-thumbnail.png"

import useSiteData from "../../hooks/useSiteData"
import { skeletonWrapperArray } from "../mui/SkeletonWrapper"

/**
 * Cat row
 */
const CategoryRow = () => {
    const { isLoadingSiteData, categories } = useSiteData()
    const [isDragging, setIsDragging] = useState(false)
    const responsive = {
        desktopLarge: {
            breakpoint: { max: Number.MAX_SAFE_INTEGER, min: 1600 },
            items: 7,
        },
        desktop: {
            breakpoint: { max: 1600, min: 1200 },
            items: 5,
        },
        tablet: {
            breakpoint: { max: 1200, min: 650 },
            items: 4,
        },
        mobile: {
            breakpoint: { max: 650, min: 0 },
            items: 3,
        },
    }

    return (
        <>
            <div className='category-row-wrapper'>
                {categories || isLoadingSiteData ? (
                    <Carousel
                        containerClass='carousel-container'
                        itemClass='carousel-item'
                        arrows={true}
                        autoPlaySpeed={3000}
                        centerMode={false}
                        responsive={responsive}
                        swipeable
                        draggable
                        infinite
                        beforeChange={() => setIsDragging(true)}
                        afterChange={() => setIsDragging(false)}
                        itemScope
                        itemType='https://schema.org/ItemList'
                    >
                        {isLoadingSiteData
                            ? skeletonWrapperArray({
                                  count: 6,
                                  variant: "rounded",
                                  width: "100%",
                                  height: 200,
                                  callback: (skeleton) => <div className='category-card-row-wrapper'>{skeleton}</div>,
                              })
                            : categories?.map((c, i) => {
                                  return (
                                      <Link
                                          to={`/store/${c.routeName}`}
                                          key={i}
                                          className='category-card-row-wrapper'
                                          draggable={false}
                                          onClick={(e) => {
                                              if (isDragging) {
                                                  e.preventDefault()
                                              }
                                          }}
                                          itemProp='itemListElement'
                                      >
                                          <meta itemProp='position' content={i + 1} />
                                          <div className='cat-card-image'>
                                              <img
                                                  src={c.thumbnailUrl}
                                                  crossOrigin='anonymous'
                                                  referrerPolicy='same-origin'
                                                  alt={c.name}
                                                  loading='lazy'
                                                  draggable={false}
                                                  className='cover'
                                              />
                                          </div>
                                          <div className='flex-row-center-vertical flex-spacebetween' itemProp='item'>
                                              <p className='cat-name' draggable={false} itemProp='item'>
                                                  {c.name}
                                              </p>
                                          </div>
                                      </Link>
                                  )
                              })}

                        <Link
                            to='/special-offers'
                            className='category-card-row-wrapper'
                            draggable={false}
                            onClick={(e) => {
                                if (isDragging) {
                                    e.preventDefault()
                                }
                            }}
                        >
                            <div className='cat-card-image'>
                                <img
                                    src={SpecialOffersThumbnail}
                                    crossOrigin='anonymous'
                                    alt='special offers'
                                    referrerPolicy='same-origin'
                                    draggable={false}
                                    className='cover'
                                />
                            </div>
                            <div className='flex-row-center-vertical flex-spacebetween'>
                                <p className='cat-name' draggable={false}>
                                    Special Offers
                                </p>
                            </div>
                        </Link>
                    </Carousel>
                ) : null}
            </div>

            <div className='desktop-category-row-wrapper'>
                {categories || isLoadingSiteData ? (
                    <Carousel
                        containerClass='carousel-container'
                        itemClass='carousel-item'
                        arrows={true}
                        autoPlaySpeed={3000}
                        centerMode={false}
                        responsive={responsive}
                        beforeChange={() => setIsDragging(true)}
                        afterChange={() => setIsDragging(false)}
                        itemScope
                        itemType='https://schema.org/ItemList'
                    >
                        {isLoadingSiteData
                            ? skeletonWrapperArray({
                                  count: 6,
                                  variant: "rounded",
                                  width: "100%",
                                  height: 280,
                                  callback: (skeleton) => <div className='category-card-row-wrapper'>{skeleton}</div>,
                              })
                            : categories?.map((c, i) => {
                                  return (
                                      <Link
                                          key={i}
                                          to={`/store/${c.routeName}`}
                                          className='category-card-row-wrapper'
                                          draggable={false}
                                          onClick={(e) => {
                                              if (isDragging) {
                                                  e.preventDefault()
                                              }
                                          }}
                                          itemProp='itemListElement'
                                      >
                                          <meta itemProp='position' content={i + 1} />
                                          <div className='cat-card-image'>
                                              <motion.img
                                                  whileHover={{
                                                      scale: 1.05,
                                                      transition: { duration: 0.3 },
                                                  }}
                                                  src={c.thumbnailUrl}
                                                  alt={c.name}
                                                  draggable={false}
                                                  className='cover'
                                                  crossOrigin='anonymous'
                                                  referrerPolicy='same-origin'
                                              />
                                          </div>
                                          <div className='flex-row-center-vertical flex-spacebetween' itemProp='item'>
                                              <p className='cat-name' itemProp='name'>
                                                  {c.name}
                                              </p>
                                              <div className='cat-arrow-wrapper'>
                                                  <TrendingFlatIcon className='cat-arrow' />
                                              </div>
                                          </div>
                                      </Link>
                                  )
                              })}

                        <Link
                            to='/special-offers'
                            className='category-card-row-wrapper'
                            draggable={false}
                            onClick={(e) => {
                                if (isDragging) {
                                    e.preventDefault()
                                }
                            }}
                        >
                            <div className='cat-card-image'>
                                <motion.img
                                    whileHover={{
                                        scale: 1.05,
                                        transition: { duration: 0.3 },
                                    }}
                                    src={SpecialOffersThumbnail}
                                    alt='special offers'
                                    draggable={false}
                                    className='cover'
                                    crossOrigin='anonymous'
                                    referrerPolicy='same-origin'
                                />
                            </div>
                            <div className='flex-row-center-vertical flex-spacebetween'>
                                <p className='cat-name' draggable={false}>
                                    Special Offers
                                </p>
                                <div className='cat-arrow-wrapper'>
                                    <TrendingFlatIcon className='cat-arrow' />
                                </div>
                            </div>
                        </Link>
                    </Carousel>
                ) : null}
            </div>
        </>
    )
}

export default CategoryRow
