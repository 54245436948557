import React from "react"
import ReactMarkdown from "react-markdown"

import ErrorBoundary from "./errors/ErrorBoundary"
import ErrorBlock from "./errors/ErrorBlock"

function MarkdownWrapper({ className = "", children }) {
    if (typeof children === "string") {
        // sanitize the mark down
        // replace {LF} with {SPACE}{SPACE}{LF} for new lines
        // https://www.markdownguide.org/basic-syntax/
        children = children.replaceAll("\n", "  \n")
    }
    return (
        <ErrorBoundary fallback={<ErrorBlock error={children} errorTitle='Markdown Error' />}>
            <ReactMarkdown className={`markdown ${className}`}>{children}</ReactMarkdown>
        </ErrorBoundary>
    )
}

export default MarkdownWrapper
