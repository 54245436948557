import React, { useState, useEffect } from "react"
import { useParams, useNavigate } from "react-router"
import { Link } from "react-router-dom"
import { motion } from "framer-motion"

import axios from "../components/axios"
import MetaData, { metaDescription, metaKeywords, metaTitle } from "../components/MetaData"
import ExtraInfoBlock from "../components/ExtraInfoBlock"
import Breadcrumbs from "../components/navigation/Breadcrumbs"
import { skeletonWrapperArray } from "../components/mui/SkeletonWrapper"
import CategoryHeader from "../components/page-components/CategoryHeader"

/**
 * The Category page
 */
const Category = () => {
    const { categoryRoute } = useParams()
    const navigate = useNavigate()

    const [categories, setCategories] = useState()
    const [pageInfo, setPageInfo] = useState()

    /**
     * Load the data
     */
    const getCategoryData = async (abortController) => {
        try {
            const [categoriesRes, pageInfoRes] = await Promise.all([
                axios.get(`/api/category/page/cat/${categoryRoute}`, { signal: abortController?.signal }),
                axios.get(`/api/category/pageinfo/${categoryRoute}`, { signal: abortController?.signal }),
            ])
            if (pageInfoRes.status === 200) {
                setCategories(categoriesRes.data)
                setPageInfo(pageInfoRes.data)
            } else {
                navigate("/")
            }
        } catch (e) {
            if (!abortController?.signal?.aborted) console.error(e)
        }
    }

    useEffect(() => {
        const abortController = new AbortController()
        getCategoryData(abortController)
        return () => abortController.abort()
    }, [])

    useEffect(() => {
        const abortController = new AbortController()
        getCategoryData(abortController)
        return () => abortController.abort()
    }, [categoryRoute])

    return (
        <>
            <MetaData description={metaDescription(pageInfo)} title={metaTitle(pageInfo)} keywords={metaKeywords(pageInfo)} />
            <div className='page-wrapper'>
                <CategoryHeader
                    heading={pageInfo ? pageInfo.name : categoryRoute}
                    subheading={pageInfo && pageInfo.description}
                    backgroundImage={pageInfo && pageInfo.bannerUrl}
                />

                <div className='content-section-wrapper'>
                    <ExtraInfoBlock text={pageInfo?.extraInfo} />
                    <Breadcrumbs type='CAT' category={{ category: pageInfo?.name ?? categoryRoute, catRoute: categoryRoute }} />

                    <div className='category-section-wrapper'>
                        {categories
                            ? categories.map((c, i) => {
                                  return (
                                      <Link key={i} to={`/store/${categoryRoute}/${c.routeName}`} className='category-card-wrapper'>
                                          <div className='cat-card-image'>
                                              <motion.img
                                                  whileHover={{
                                                      scale: 1.05,
                                                      transition: { duration: 0.1 },
                                                  }}
                                                  src={c.thumbnailUrl}
                                                  alt={c.name}
                                                  loading='lazy'
                                                  crossOrigin='anonymous'
                                                  referrerPolicy='same-origin'
                                              />
                                          </div>
                                          <div className='flex-row-center-vertical flex-spacebetween'>
                                              <p className='cat-name'>{c.name}</p>
                                          </div>
                                      </Link>
                                  )
                              })
                            : skeletonWrapperArray({
                                  count: 5,
                                  variant: "rounded",
                                  width: "95%",
                                  height: 300,
                                  callback: (skeleton) => <div className='category-card-wrapper'>{skeleton}</div>,
                              })}
                    </div>
                </div>
            </div>
        </>
    )
}

export default Category
