import React, { lazy, Suspense } from "react"
import { Navigate, Route, Routes } from "react-router-dom"
import { HelmetProvider } from "react-helmet-async"

import "./variables.css"
import "./pages/_styling/general.css"

import Home from "./pages/Home"
import Category from "./pages/Category"
import SubCategory from "./pages/SubCategory"
import Product from "./pages/Product"
import Range from "./pages/Range"
import SearchPage from "./pages/SearchPage"
import Contact from "./pages/Contact"
import FindUsPage from "./pages/FindUsPage"
import Unauthorised from "./pages/Unauthorised"
import Clearance from "./pages/Clearance"
import Brand from "./pages/Brand"
import PropertyPage from "./pages/PropertyPage"
import CustomPage from "./pages/CustomPage"

import { UserProvider } from "./hooks/UserContext"
import { SiteDataProvider } from "./hooks/SiteDataContext"
import { AlertProvider } from "./components/alertContext/AlertContext"

import Layout from "./components/Layout"
import LatestProducts from "./pages/LatestProducts"

const AdminPath = lazy(() => import("./components/admin/AdminPath"))

// Create the context
const helmetContext = {}
const alertContext = {}

function App() {
    return (
        <HelmetProvider context={helmetContext}>
            <AlertProvider context={alertContext}>
                <UserProvider>
                    <SiteDataProvider>
                        <Routes>
                            <Route path={"/"} element={<Layout />}>
                                <Route path={"/"} element={<Home />} />

                                <Route path={"/find-us"} element={<FindUsPage />} />
                                <Route path={"/contact"} element={<Contact />} />
                                <Route path={"/contact/:variantUuid"} element={<Contact />} />

                                <Route path={"/store"} element={<Navigate to='/' />} />
                                <Route path={"/store/:categoryRoute"} element={<Category />} />
                                <Route path={"/store/:categoryRoute/:subCategoryRoute"} element={<SubCategory />} />
                                <Route path={"/latest-products"} element={<LatestProducts />} />
                                <Route path={"/special-offers"} element={<Clearance />} />
                                <Route path={"/clearance"} element={<Navigate to='/special-offers' replace={true} />} />
                                <Route path={"/store/special-offers"} element={<Navigate to='/special-offers' replace={true} />} />

                                <Route path={"/product/:productUuid/:productName?/:variantUuid?"} element={<Product />} />
                                <Route path={"/range/:rangeRoute"} element={<Range />} />
                                <Route path={"/brand/:brandUuid/:brandName?"} element={<Brand />} />
                                <Route path={"/brand/:categoryRoute/:subCategoryRoute/:brandUuid/:brandName?"} element={<Brand />} />
                                <Route path={"/property/:propertyId/:propertyName?"} element={<PropertyPage />} />
                                <Route path={"/property/:categoryRoute/:subCategoryRoute/:propertyId/:propertyName?"} element={<PropertyPage />} />
                                <Route path={"/search/:searchQuery?"} element={<SearchPage />} />
                                <Route path={"/terms"} element={<CustomPage fixedPageId='53982239-a4a2-11ee-bab4-002248998e4f' />} />
                                <Route path={"/privacy-policy"} element={<CustomPage fixedPageId='61121ed5-a4a2-11ee-bab4-002248998e4f' />} />
                                <Route path={"/disclaimer"} element={<CustomPage fixedPageId='53982e02-a4a2-11ee-bab4-002248998e4f' />} />
                                <Route path={"/interest-free-credit"} element={<CustomPage fixedPageId='547f5b6d-0de9-4cb0-b0f5-d01f7df62acc' />} />
                                <Route path={"/customer-complaint"} element={<CustomPage fixedPageId='d7a2f696-e39a-4d5e-8c51-039cf7dd231e' />} />
                                <Route path={"/vulnerable-customers-policy"} element={<CustomPage fixedPageId='bb10a122-e4ee-41b0-b828-d15be74dfbf4' />} />
                                <Route path={"/page/:pageId"} element={<CustomPage />} />
                                <Route path={"/unauthorised"} element={<Unauthorised />} />
                                <Route path='*' element={<Unauthorised />} />
                            </Route>

                            <Route
                                path={"/admin/*"}
                                element={
                                    <Suspense fallback={<>...</>}>
                                        <AdminPath />
                                    </Suspense>
                                }
                            />
                        </Routes>
                    </SiteDataProvider>
                </UserProvider>
            </AlertProvider>
        </HelmetProvider>
    )
}

export default App
