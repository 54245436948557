export const defaultKeywords = [
    "discount furniture store",
    "discount furniture",
    "furniture store",
    "furniture shop",
    "furniture",
    "home interiors",
    "home furnishings",
    "home decor",
    "cleethorpes",
    "grimsby",
    "lincoln",
    "lincolnshire",
]

export const AlertTypes = {
    Info: "info",
    Success: "success",
    Warning: "warning",
    Error: "error",
}
Object.freeze(AlertTypes)
