import React, { useEffect, useState } from "react"
import axios from "../axios"
import CarouselItem from "./CarouselItem"
import "../_styling/carousel.css"
import Carousel from "react-multi-carousel"
import { skeletonWrapperArray } from "../mui/SkeletonWrapper"

/**
 * Large carousel, one item per page
 */
const LargeCarousel = ({ autoPlay = true }) => {
    const [isLoading, setIsLoading] = useState(true)
    const [carousels, setCarousels] = useState(null)

    /**
     * Get the data
     */
    const getInitialData = async (abortController) => {
        setIsLoading(true)
        try {
            const carouselRes = await axios.get(`/api/carousel`, { signal: abortController?.signal })
            setCarousels(carouselRes.data)
        } catch (e) {
            if (!abortController?.signal?.aborted) console.error(e)
        } finally {
            setIsLoading(false)
        }
    }

    /**
     * Called when the page first loads
     */
    useEffect(() => {
        const abortController = new AbortController()
        getInitialData(abortController)
        // Clean up function
        return () => abortController.abort()
    }, [])

    /**
     * Break points
     */
    const responsive = {
        desktopLarge: {
            breakpoint: { max: Number.MAX_SAFE_INTEGER, min: 0 },
            items: 1,
        },
    }

    // if there is only one item, doent autoplay or show the arrows
    const autoPlayValue = autoPlay ? (carousels && carousels.length > 1 ? true : false) : undefined
    const showArrows = carousels && carousels.length > 1 ? true : false

    if (!isLoading) {
        if (!carousels || carousels.length === 0) return null
    }

    return (
        <div className='flex-row carousel-large-wrapper'>
            <Carousel
                containerClass='carousel-large-container'
                itemClass='carousel-large-item'
                dotListClass='carousel-large-dot'
                arrows={showArrows}
                autoPlaySpeed={6000}
                autoPlay={autoPlayValue}
                centerMode={false}
                responsive={responsive}
                showDots={showArrows}
                infinite
                itemScope
                itemType='https://schema.org/ItemList'
            >
                {isLoading
                    ? skeletonWrapperArray({
                          count: 4,
                          variant: "rounded",
                          width: "100%",
                          height: 500,
                          callback: (skeleton) => (
                              <div className='hp-carousel-item' itemProp='itemListElement'>
                                  {skeleton}
                              </div>
                          ),
                      })
                    : carousels?.map((c, i) => {
                          return <CarouselItem key={i} index={i + 1} carousel={c} />
                      })}
            </Carousel>
        </div>
    )
}

export default LargeCarousel
