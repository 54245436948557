import axios from "axios"
import React, { createContext, useState } from "react"

const UserContext = createContext({})

export const defaultUserState = {
    loggedIn: false,
    uuid: null,
    name: null,
}

export const UserProvider = ({ children }) => {
    const [auth, setAuth] = useState(defaultUserState)

    const [currentRoute, setCurrentRoute] = useState(null)

    const refreshAuth = async () => {
        try {
            const response = await axios.get("/api/login")
            if (response.data.loggedIn) {
                setAuth((currentState) => {
                    return {
                        ...currentState,
                        ...response.data,
                    }
                })
            } else {
                setAuth(defaultUserState)
            }
        } catch (e) {
            setAuth(defaultUserState)
        }
    }

    return <UserContext.Provider value={{ auth, setAuth, refreshAuth, currentRoute, setCurrentRoute }}>{children}</UserContext.Provider>
}

export default UserContext
