import React from "react"

import "../pages/_styling/animations.css"

const Loading = ({ color = "default" }) => {
    return (
        <div className='lds-ellipsis-wrapper'>
            <div className={`lds-ellipsis lds-ellipsis-${color}`}>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
        </div>
    )
}

export default Loading
