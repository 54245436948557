import React from "react"
import { useCollapse } from "react-collapsed"
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp"
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown"

const Dimensions = ({ product, variantInfo }) => {
    const { getCollapseProps, getToggleProps, isExpanded } = useCollapse()
    return (
        <div>
            <div className='collapsable-wrapper'>
                <button className='collapsable-btn' {...getToggleProps()}>
                    {isExpanded ? (
                        <div className='flex-row-spacebetween full-width'>
                            <p>Dimensions</p>
                            <KeyboardArrowUpIcon />
                        </div>
                    ) : (
                        <div className='flex-row-spacebetween full-width'>
                            <p>Dimensions</p>
                            <KeyboardArrowDownIcon />
                        </div>
                    )}
                </button>
                <section className='collapsable-info' {...getCollapseProps()}>
                    {variantInfo.dimensions.height && (
                        <div key={product.uuid + "5"}>
                            <p className='p-description'>
                                (H) {variantInfo.dimensions.height}cm x (W) {variantInfo.dimensions.width}cm x (D) {variantInfo.dimensions.depth}cm{" "}
                            </p>
                        </div>
                    )}
                </section>
            </div>
        </div>
    )
}

export default Dimensions
