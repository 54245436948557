import React from "react"

/**
 * A basic divider line, like a hr
 * @param {Object} props - The component props
 * @param {number} props.padding the padding at the top and bottom
 */
const RedDivider = ({ padding }) => {
    const styles = {
        padding: padding + "px 0",
        width: "100%",
    }

    return (
        <div style={styles} role='separator'>
            <div className='divider-line-red' />
        </div>
    )
}

export default RedDivider
